import { createSelector } from 'reselect';
import { AppState } from 'store/rootReducer';
import { Notification } from 'shared/types/Notification';
import { ProfileState } from './profileReducer';

export const uiStateSelector = (state: AppState): typeof state.ui => state.ui;
export const profileStateSelector = (state: AppState): ProfileState => state.profile;
export const userEmailSelector = (state: AppState): string | undefined => state.ui.user?.email;

export const profileSelector = createSelector(profileStateSelector, (state: ProfileState) =>
  state.selectedProfileId ? state.userProfiles[state.selectedProfileId] : null
);

export const authdUserProfileSelector = createSelector(
  profileStateSelector,
  userEmailSelector,
  (state, email) => {
    const result = Object.values(state.userProfiles).filter((user) => user.email === email);
    return result.length ? result[0] : null;
  }
);

export const userNotifications = createSelector(uiStateSelector, (uiState) =>
  Object.values(uiState.notifications)
    .filter((item) => item.category !== 'received' || item.acceptRequest === '3')
    .sort((a, b) => (a.notificationTime > b.notificationTime ? -1 : 1))
    .map(Notification.fromJson)
);
