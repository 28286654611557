import React from 'react';
import dayjs from 'dayjs';
import { Match } from 'shared/types/Match';
import Styled from './MatchCard.styles';
import { IcoStar } from '../../icons/star';
import { isMatchLive } from '../../../utils';

const penaltiesSummary = (match: Match): { home: string; away: string } => {
  const { home = 0, away = 0 } = match.penaltyScores ?? {};
  const info = `Winners on penalties (${home} - ${away})`;
  return {
    home: home > away ? info : '',
    away: home < away ? info : '',
  };
};

export interface Props {
  match: Match;
}

export const MatchCard: React.FC<Props> = ({ match, ...props }) => {
  const matchTime = dayjs(match.startAt._seconds * 1000).format('DD dd. MMM YYYY. HH:mm');
  const penalties = penaltiesSummary(match);
  const totalTimeInMins = Math.ceil(match.totalTime / 60);
  const isFullTime = totalTimeInMins >= 90;
  const overtime = Math.max(0, totalTimeInMins - 90);

  const players = [match.homeTeamPlayers[0], match.awayTeamPlayers[0]]
    .filter(Boolean)
    .map((player) => player.fullName);

  const isLive = isMatchLive(match);

  return (
    <Styled.Wrapper {...props}>
      <div>
        {match.title} {match.isLive && ' (Live)'}
      </div>
      <Styled.MatchTime>{matchTime}</Styled.MatchTime>
      <Styled.MatchLength>
        {isFullTime && 'Fulltime'}
        {overtime > 0 && `: 90:00+${overtime}`}
      </Styled.MatchLength>
      <Styled.Score score={match.score0 ?? '0'}>
        <h5>{match.homeTeamName}</h5>
        <small>{penalties.home}</small>
      </Styled.Score>
      <Styled.Score score={match.score1 ?? '0'}>
        <h5>{match.awayTeamName}</h5>
        <small>{penalties.away}</small>
      </Styled.Score>
      <Styled.Players>
        {match.pom ? (
          <>
            <Styled.Avatar size={50} src={match.pom.avatar || '/images/user-placeholder.svg'} />
            <span className="name">{match.pom.name}</span>
            <IcoStar />
            <span className="hint">Player of the match</span>
          </>
        ) : (
          <>
            <Styled.Avatar
              size={50}
              src={match.homeTeamPlayers[0]?.avatar || '/images/user-placeholder.svg'}
            />
            {match.awayTeamPlayers.length > 0 && (
              <Styled.Avatar
                size={50}
                src={match.awayTeamPlayers[0]?.avatar || '/images/user-placeholder.svg'}
              />
            )}
            <span className="name">{players.join(', ')}</span>
          </>
        )}
      </Styled.Players>
    </Styled.Wrapper>
  );
};
