import { getType, Reducer } from 'typesafe-actions';
import { AnyAction } from 'redux';
import { mergeRight } from 'ramda';
import { UserProfile } from 'shared/types/UserProfile';
import { Steps } from '../Steps.enum';
import actions from './registerActions';
import { ProfileType } from '../../../shared/types/ProfileType.enum';

export interface RegisterState {
  isFetching: boolean;
  registrationError: { code: string; message: string } | null;
  values: Partial<UserProfile>;
  activeStep: Steps;
}

export const initialRegistrationState: RegisterState = {
  isFetching: false,
  registrationError: null,
  values: {},
  activeStep: Steps.Basic,
};

const registerReducer: Reducer<RegisterState, AnyAction> = (
  state = initialRegistrationState,
  action: AnyAction
) => {
  const { type, payload } = action;
  const updateState = mergeRight(state);

  switch (type) {
    case getType(actions.stepCompleted): {
      let nextStep = null;
      if (state.activeStep === Steps.Basic && payload.type === ProfileType.SIMPLE) {
        nextStep = Steps.OurValues;
      } else {
        const stepsList = Object.values(Steps);
        nextStep = stepsList[stepsList.indexOf(state.activeStep) + 1];
      }

      return updateState<Partial<RegisterState>>({
        values: { ...state.values, ...payload },
        activeStep: nextStep,
      });
    }

    case getType(actions.gotoStep): {
      return updateState<Partial<RegisterState>>({ activeStep: payload });
    }

    case getType(actions.createAccount.request): {
      return updateState<Partial<RegisterState>>({ isFetching: true });
    }

    default:
      return state;
  }
};

export default registerReducer;
