import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { Normalize } from 'styled-normalize';
import { ToastProvider } from 'react-toast-notifications';
import { AuthProvider } from 'shared/services/auth/AuthProvider';
import App from './App/App';
import * as serviceWorker from './serviceWorker';
import { rootStore } from './store/rootStore';
import Firebase from './firebase.config';

ReactDOM.render(
  <Provider store={rootStore}>
    <AuthProvider firebase={new Firebase()}>
      <Normalize />
      <ToastProvider placement={'bottom-right'}>
        <App />
      </ToastProvider>
    </AuthProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
