import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import styled, { css } from 'styled-components/macro';
import { ToggleButton } from 'shared/components/buttons/ToggleButton';
import { device } from 'shared/styles/MediaQuery';
import { Header } from './components/Header';

const Page = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 1rem;
`;

const columnTemplate = (columns?: number, width = '140px'): any => {
  return columns
    ? css`
        --pageWidth: calc(100vw - 2rem);
        --baseColumnWidth: calc(var(--pageWidth) / ${columns});
        --adjustedColumnWidth: calc(var(--baseColumnWidth) - ${0.5 - 0.5 / columns}rem);
        grid-template-columns: repeat(${columns}, min(${width}, var(--adjustedColumnWidth)));
      `
    : css`
        grid-template-columns: repeat(auto-fit, ${width});
      `;
};

const ButtonGroup = styled.div<{ columns?: number; width?: string }>`
  display: grid;
  grid-gap: 0.5rem;
  justify-content: flex-start;

  ${(props) => columnTemplate(props.columns, props.width)}
  ${device.tablet} {
    justify-content: center;
  }
`;

interface Props {
  title: string;
  options: string[];
  value?: string;
  onChange: (value: string) => void;
  columns?: number;
  columnWidth?: string;
}

export const OptionGroup: React.FC<RouteComponentProps & Props> = ({
  title,
  options,
  value: initialValue = null,
  onChange,
  columns,
  columnWidth,
}) => {
  const [value, setValue] = useState<string | null>(initialValue);

  useEffect(() => {
    value && onChange(value);
  }, [value]);

  return (
    <Page>
      <Header title={title} />
      <ButtonGroup columns={columns} width={columnWidth}>
        {options.map((option, idx) => (
          <ToggleButton
            key={option}
            tabIndex={idx}
            isActive={value === option}
            onClick={() => setValue(option)}
          >
            {option}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </Page>
  );
};
