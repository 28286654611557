import styled from 'styled-components/macro';
import { Button } from 'shared/components/buttons/Button';
import { LogoLarge } from 'shared/icons/logo-large';
import { device } from 'shared/styles/MediaQuery';
import { Modal as _Modal } from 'shared/components/modal/Modal';
import { Colours } from 'shared/styles/Colours';

const Page = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 1 550px;
  padding: 2rem;

  h1 {
    margin: 3rem 0;
  }

  ol {
    list-style-type: decimal;
    padding-left: 1.1rem;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.2px;

    ${device.tablet} {
      font-size: 1.5rem;
    }

    li {
      padding-left: 1rem;
      margin-bottom: 1rem;
    }
  }
`;

const Logo = styled(LogoLarge)`
  flex: 0 0 auto;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 4rem;

  ${Button} {
    margin: 0 0.5rem;
  }
`;

const Modal = styled(_Modal)`
  align-items: center;
  background-color: ${Colours.primaryBackground};
  padding: 2rem 4rem;
  width: min(440px, 100%);

  h3 {
    text-align: center;
    margin-bottom: 2rem;
  }
`;

export default {
  Page,
  Wrapper,
  Logo,
  ButtonGroup,
  Modal,
};
