import React from 'react';
import styled from 'styled-components/macro';
import { Colours } from '../styles/Colours';
import { PlayingPosition, propsForPosition } from '../types/PlayingPosition';

const Position = styled.div<{
  bgColour: string;
  name: string;
  color?: string;
}>`
  background-color: ${(props) => props.bgColour};
  border-radius: 50%;
  color: ${(props) => props.color ?? Colours.tertiaryText};
  font-weight: 600;
  height: 3rem;
  line-height: 3rem;
  overflow: hidden;
  text-align: center;
  width: 3rem;
`;

export interface Props {
  position: PlayingPosition;
}

export const PositionBadge: React.FC<Props> = ({ position, ...props }) => {
  const positionProps: any = position && propsForPosition(position);

  return (
    <Position {...positionProps} {...props}>
      {position}
    </Position>
  );
};
