import styled from 'styled-components/macro';
import { Colours } from 'shared/styles/Colours';
import { device } from 'shared/styles/MediaQuery';
import { Loader as _Loader } from 'shared/icons/loader';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 0 1rem 5rem;
`;

const Wrapper = styled.div`
  width: min(900px, calc(100% - 2rem));
  margin: auto;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;

  & > h6 {
    display: flex;
    font-weight: 500;
    justify-content: space-between;
    margin-top: 4rem;
    width: 100%;
    flex: 1 0 auto;
  }
`;

const PlayerName = styled.h3`
  margin: 2rem 0 0;
`;

const PlayingStyle = styled.p`
  color: ${Colours.secondaryText};
  margin: 0 0 0.5rem;
`;

const Badges = styled.div`
  display: flex;
  flex: 1 0 auto;
`;

const SquadsWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  flex: 1 0 auto;
`;

const Squads = styled.ul`
  align-self: flex-start;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, 200px);
  grid-column-gap: 2rem;
  height: 236px;
  padding: 0 0 1rem 0;
  overflow: auto;

  li {
    min-width: 200px;
  }
`;

const Matches = styled.ul<{ showsInDrawer: boolean }>`
  align-self: stretch;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: repeat(2, 285px);
  grid-row-gap: 2.25rem;
  grid-auto-columns: min(434px, 100%);
  padding: 0;

  ${({ showsInDrawer }) => (showsInDrawer ? device.tv : device.tablet)} {
    grid-auto-flow: column;
    grid-auto-rows: 285px;
    grid-column-gap: 2.25rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: unset;
    width: auto;
  }
`;

const Stats = styled.ul<{ showsInDrawer: boolean }>`
  align-self: stretch;
  display: grid;
  grid-auto-rows: 140px;
  grid-column-gap: 2rem;
  grid-template-columns: repeat(2, min(45%, 202px));
  grid-row-gap: 2rem;
  padding: 0;

  ${({ showsInDrawer }) => (showsInDrawer ? device.tv : device.tablet)} {
    grid-auto-flow: column;
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Loader = styled(_Loader)`
  margin: auto;
`;

const Follow = styled.button`
  all: unset;
  border-radius: 18px;
  height: 36px;
  background-color: ${Colours.secondaryElement};
  padding: 0 22px;
  margin: 5px 0 10px 0;
  cursor: pointer;
`;

export default {
  Page,
  Wrapper,
  PlayerName,
  PlayingStyle,
  Badges,
  SquadsWrapper,
  Squads,
  Matches,
  Stats,
  Loader,
  Follow,
};
