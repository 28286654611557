import React from 'react';
import { find, mergeDeepRight, propEq } from 'ramda';
import { Colours } from '../../styles/Colours';
import { PlayingPositionColours, PlayingPosition } from '../../types/PlayingPosition';
import Styled from './Position.styles';
import { match } from 'react-router';

const PositionsLayout = [
  { name: 'F', col: 3, color: Colours.primaryText },
  { name: 'S', col: 4, color: Colours.primaryText, style: { top: '-1.5rem' } },
  { name: 'LW', col: 1, color: Colours.primaryText },
  { name: 'AM', col: 3, color: Colours.primaryText },
  { name: 'RW', col: 5, color: Colours.primaryText },
  { name: 'LM', col: 1 },
  { name: 'CM', col: 3 },
  { name: 'RM', col: 5 },
  { name: 'LWB', col: 1, style: { left: '-1.5rem' } },
  { name: 'DM', col: 3 },
  { name: 'RWB', col: 5, style: { right: '-1.5rem' } },
  { name: 'SW', col: 3 },
  { name: 'GK', col: 3, color: Colours.primaryText },
];

const positionLayoutByName = (name: string): { name: string; col: number; color?: Colours } =>
  find(propEq('name', name), PositionsLayout)!;

export interface Props {
  value?: PlayingPosition;
  onChange?: (value: PlayingPosition) => void;
}

export const Position: React.FC<Props> = ({ value, onChange, ...props }) => {
  const positions = PlayingPositionColours.map((position) =>
    mergeDeepRight(positionLayoutByName(position.name), position)
  );

  return (
    <Styled.PositionGrid {...props}>
      {positions.map((position: any) => (
        <Styled.PlayingPosition
          key={position.name}
          isActive={value === position.name}
          onClick={() => onChange && onChange(position.name as PlayingPosition)}
          {...position}
        />
      ))}
    </Styled.PositionGrid>
  );
};
