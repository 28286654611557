import { Link } from '@reach/router';
import styled from 'styled-components/macro';
import { device } from '../../styles/MediaQuery';
import { Colours } from '../../styles/Colours';
import { SearchBar as _SearchBar } from '../search-bar/SearchBar';
import { IcoSearch as _IcoSearch } from '../../icons/search';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 70px 1fr repeat(3, 70px);
  justify-items: center;
  align-items: center;
  padding: 1.5rem 1rem;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;

  ${device.tablet} {
    grid-template-columns: 210px 1fr repeat(3, 70px);
  }
`;

const Notifications = styled.div<{ count: number }>`
  position: relative;
  width: 1.5rem;
  cursor: pointer;

  &:after {
    background-color: ${Colours.secondaryElement};
    border-radius: 50%;
    content: ${(props: any) => `' ${props.count} '`};
    font-size: 0.75rem;
    line-height: 1.5rem;
    position: absolute;
    text-align: center;
    top: -12px;
    right: -9px;
    width: 1.5rem;
  }
`;

const NavLink = styled(Link)`
  cursor: pointer;
  padding: 0.5rem;
  text-align: center;
`;

const MenuLink = styled.div`
  padding: 0.5rem;
  text-align: center;
  justify-self: flex-end;
`;

const HomeLink = styled(Link)`
  justify-self: flex-start;
`;

const IcoSearch = styled(_IcoSearch)`
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  color: ${Colours.secondaryText};

  ${device.tablet} {
    display: none;
  }
`;

const SearchBar = styled(_SearchBar)`
  display: none;

  ${device.tablet} {
    display: flex;
    max-width: 660px;
  }
`;

export default {
  Wrapper,
  IcoSearch,
  SearchBar,
  Notifications,
  NavLink,
  MenuLink,
  HomeLink,
};
