import React from 'react';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { MatchEvent } from 'shared/types/MatchEvent';
import { IcoGoal } from '../../../shared/icons/goal';
import { IcoPenMissed } from '../../../shared/icons/pen-missed';
import { IcoPenSaved } from '../../../shared/icons/pen-saved';

dayjs.extend(duration);

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(30%, 150px) 60px 40px 60px minmax(30%, 150px);
  grid-row-gap: 20px;
  margin-bottom: 40px;
`;

const EventPlayers = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: center;
`;

const EventType = styled.div`
  flex: 0 0 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 1.2rem;
  }
`;

const EventTime = styled.div`
  background-color: white;
  border-radius: 50%;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 40px;
  font-size: 1.1rem;
  height: 40px;
`;

export const Event: React.FC<{ event: MatchEvent }> = ({ event, ...props }) => {
  const time = dayjs.duration(Math.ceil(event.time), 'seconds');

  console.log(time);
  const eventIco = (event: MatchEvent): JSX.Element | string => {
    if (event.type === 'goal') {
      return <IcoGoal />;
    } else {
      switch (event.penaltyResult) {
        case 'GOAL':
          return <IcoGoal />;
        case 'Missed':
          return <IcoPenMissed />;
        case 'Saved':
          return <IcoPenSaved />;
      }
    }

    console.log(event);
    return event.type;
  };
  return (
    <Wrapper {...props}>
      <EventPlayers>{event.isHomeTeam ? <span>{event.player1.fullName}</span> : null}</EventPlayers>
      <EventType>{event.isHomeTeam ? eventIco(event) : null}</EventType>
      <EventTime>{time.minutes()}&apos;</EventTime>
      <EventType>{!event.isHomeTeam ? eventIco(event) : null}</EventType>
      <EventPlayers>
        {!event.isHomeTeam ? (
          <>
            <span>{event.player1.fullName}</span>
            <span>{event.player2.fullName}</span>
            <span>{event.player3.fullName}</span>
          </>
        ) : null}
      </EventPlayers>
    </Wrapper>
  );
};
