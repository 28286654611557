import React from 'react';
import { RouteComponentProps } from '@reach/router';
import BasicsForm from './BasicsForm';
import Styled from './Basics.styles';

interface Props {
  onSubmit: () => void;
  onValid: (values: Record<string, any>) => void;
  values?: Record<string, any>;
}

export const BasicsWrapper: React.FC<RouteComponentProps & Props> = ({
  onSubmit,
  onValid,
  values,
}) => {
  const Header: React.FC = () => (
    <>
      <h1>Let’s start with the basics</h1>
      <Styled.Hint>
        Already have an account?
        <a href="/">Login here</a>
      </Styled.Hint>
    </>
  );

  return (
    <Styled.Page>
      <Styled.CoverImage>
        <Header />
      </Styled.CoverImage>

      <Styled.ScrollPane>
        <BasicsForm onSubmit={onSubmit} onValid={onValid} values={values}>
          <Styled.Logo src="/images/logo.svg" alt="Footify" />
          <Header />
        </BasicsForm>
        <Styled.Footer>&copy; 2018 Footify Limited. All rights reserved</Styled.Footer>
      </Styled.ScrollPane>
    </Styled.Page>
  );
};

export default BasicsWrapper;
