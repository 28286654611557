import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { device } from 'shared/styles/MediaQuery';

const AnimationWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  // https://stackoverflow.com/questions/28636832/firefox-overflow-y-not-working-with-nested-flexbox
  min-height: 0;

  ${device.tablet} {
    height: 100%;
  }
`;

const MotionWrapper = styled(motion.div)`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export default {
  AnimationWrapper,
  MotionWrapper,
  Page,
};
