import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import uiActions from 'store/uiActions';
import actions from 'modules/squad-profile/store/squadActions';
import { Notification } from 'shared/types/Notification';
import { IcoCheckCircle } from 'shared/icons/check-circle';
import { IcoCrossCircle } from 'shared/icons/cross-circle';
import { NotificationItem } from '../notification-item/NotificationItem';
import { Tab, Tabs } from '../tabs/tabs';
import { UndoButton } from '../buttons/UndoButton';

dayjs.extend(relativeTime);

const Notifications: React.FC<{ notifications: Notification[] }> = ({
  notifications,
  ...props
}) => {
  const general = notifications.filter((i) => i.category === 'general');
  const generalUnread = general.reduce((acc, i) => acc + (i.isRead ? 0 : 1), 0);
  const received = notifications.filter((i) => i.category === 'received');
  const receivedUnread = received.reduce((acc, i) => acc + (i.isRead ? 0 : 1), 0);
  const sent = notifications.filter((i) => i.category === 'sent');
  const dismissTimer = useRef<number | null>();
  const dispatch = useDispatch();
  const timeout = 3000;

  const acceptInvite = (requestId: string): void => {
    dispatch(actions.acceptSquadInvite.request(requestId));
  };

  const rejectInvite = (requestId: string): void => {
    dispatch(actions.rejectSquadInvite.request(requestId));
  };

  const handleTabSelected = (index: number): void => {
    dismissTimer.current && clearTimeout(dismissTimer.current);

    switch (index) {
      case 0:
        dismissTimer.current = window.setTimeout(() => {
          dismissTimer.current = null;
          generalUnread > 0 && dispatch(uiActions.markNotificationsRead.request('general'));
        }, timeout);
        break;
      case 1:
        dismissTimer.current = window.setTimeout(() => {
          dismissTimer.current = null;
          receivedUnread > 0 && dispatch(uiActions.markNotificationsRead.request('received'));
        }, timeout);
        break;
    }
  };

  useEffect(
    () => () => {
      // when sidebar closes with pending timer, clear it
      dismissTimer.current && clearTimeout(dismissTimer.current);
    },
    []
  );

  return (
    <Tabs {...props} onTabSelected={handleTabSelected}>
      <Tab
        decoration={generalUnread ? '\u00b7' : undefined}
        header={
          <>
            <span>{generalUnread}</span> General
          </>
        }
      >
        {general.map((item) => (
          <NotificationItem key={item.id} notification={item} />
        ))}
      </Tab>
      <Tab
        header={
          <>
            <span>{receivedUnread}</span> Received
          </>
        }
      >
        {received.map((item) => (
          <NotificationItem key={item.id} notification={item}>
            <div className="actions">
              <UndoButton onClick={() => acceptInvite(item.requestId)}>
                <IcoCheckCircle />
              </UndoButton>
              <UndoButton onClick={() => rejectInvite(item.requestId)}>
                <IcoCrossCircle />
              </UndoButton>
            </div>
          </NotificationItem>
        ))}
      </Tab>
      <Tab header="Sent">
        {sent.map((item) => (
          <NotificationItem key={item.id} notification={item} />
        ))}
      </Tab>
    </Tabs>
  );
};

export default Notifications;
