import { createAction, createAsyncAction } from 'typesafe-actions';

const stepCompleted = createAction('register/STEP_COMPLETED')<Record<string, any>>();
const gotoStep = createAction('register/GOTO_STEP')<string>();

const createAccount = createAsyncAction(
  'register/CREATE_ACCOUNT',
  'register/CREATE_ACCOUNT_SUCCESS',
  'register/CREATE_ACCOUNT_FAILURE'
)<void, string, Error>();

export default {
  stepCompleted,
  gotoStep,
  createAccount,
};
