import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { fetchUserNotifications, markasRead } from '../modules/profile/api';
import uiActions from './uiActions';

function* loadNotifications(): Generator {
  try {
    const notifications = yield fetchUserNotifications().then((response) => response.data);
    yield put(uiActions.loadNotifications.success(notifications));
  } catch (e) {
    yield put(uiActions.loadNotifications.failure(e));
  }
}

function* markNotificationsRead(action: any): Generator {
  try {
    yield markasRead(action.payload);
    yield put(uiActions.markNotificationsRead.success());
  } catch (e) {
    yield put(uiActions.markNotificationsRead.failure(e));
  }
}

function* watchAPIRequests(): Generator {
  yield takeLatest(getType(uiActions.userAuthStateChanged), loadNotifications);
  yield takeLatest(getType(uiActions.markNotificationsRead.request), markNotificationsRead);
}

export function* uiSaga(): Generator {
  yield all([fork(watchAPIRequests)]);
}
