import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { Colours } from 'shared/styles/Colours';
import { device } from 'shared/styles/MediaQuery';
import { Button } from 'shared/components/buttons/Button';
import { Steps } from '../Steps.enum';

const Wrapper = styled.div`
  display: none;
  justify-content: space-between;
  padding: 0 2rem 1rem;

  ${device.tablet} {
    display: flex;
  }

  a {
    cursor: pointer;
  }
`;

const StepsList = styled.ul`
  display: flex;
  align-items: center;
  padding-left: 0;
`;

const Step = styled.li<{ isCompleted?: boolean }>`
  color: ${({ isCompleted }) => (isCompleted ? Colours.primaryText : Colours.secondaryText)};

  &:not(:last-of-type) {
    margin-right: 2rem;
  }
`;

const NavButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 1rem 3rem;
  color: ${Colours.primaryText};
  cursor: pointer;

  &:disabled {
    opacity: 0.7;
  }
`;

interface Props {
  steps: string[];
  activeStep: Steps;
  isActiveStepValid: boolean;
  onCancel: () => void;
  onNext: () => void;
  onGoto: (step: string) => void;
}

export const ProgressFooter: React.FC<Props> = ({
  steps,
  activeStep,
  isActiveStepValid,
  onCancel,
  onNext,
  onGoto,
}) => {
  const stepsCompleted = steps.slice(0, steps.indexOf(activeStep) + 1);
  const isStepCompleted = (step: Steps): boolean => stepsCompleted.indexOf(step) !== -1;

  useEffect(() => {
    const listener = (event: KeyboardEvent): void => {
      event.key === 'Enter' && isActiveStepValid && onNext();
    };

    document.addEventListener('keydown', listener);
    return () => document.removeEventListener('keydown', listener);
  }, [isActiveStepValid, onNext]);

  return (
    <Wrapper>
      <NavButton onClick={onCancel}>Cancel</NavButton>
      <StepsList>
        {steps.map((step) => (
          <Step key={step} isCompleted={isStepCompleted(step as any)}>
            {isStepCompleted(step as any) ? <a onClick={() => onGoto(`./step`)}>{step}</a> : step}.
          </Step>
        ))}
      </StepsList>
      {activeStep === Steps.Avatar ? (
        <Button onClick={onNext} disabled={!isActiveStepValid}>
          Create my profile
        </Button>
      ) : (
        <NavButton onClick={onNext} disabled={!isActiveStepValid}>
          Next
        </NavButton>
      )}
    </Wrapper>
  );
};
