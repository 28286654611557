import React, { ReactElement } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { IcoErrorFace } from '../../icons/error-face';
import { Colours } from '../../styles/Colours';

const rotate = keyframes`
  0% {
    opacity: .0;
    max-height: 0;
    padding: 0 1rem;
    margin-bottom: 0;
  }

  60% {
    opacity: 1;
  }

  100% {
    padding: 1rem;
    max-height: max-content;
    margin-bottom: 1rem;
  }
`;

const Wrapper = styled.div`
  animation: ${rotate} 0.4s cubic-bezier(0.175, 0.885, 0.635, 1.295);
  border: 1px solid #e11378;
  border-radius: 4px;
  color: #e11378;
  display: flex;
  font-size: 1rem;
  margin-bottom: 1rem;
  min-height: max-content;
  padding: 1rem;
  width: 100%;

  svg {
    min-width: 20px;
  }
`;

const Message = styled.div`
  padding-left: 0.5rem;

  p {
    color: ${Colours.primaryText};
  }
`;

export const ValidationError: React.FC<{ message?: string | ReactElement | null }> = ({
  message,
}) =>
  message ? (
    <Wrapper>
      <IcoErrorFace />
      <Message>
        Ooops!
        <p>{message}</p>
      </Message>
    </Wrapper>
  ) : null;
