import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from '@reach/router';
import { auth } from 'firebase';
import { AuthContext } from 'shared/services/auth/AuthContext';
import { Logo } from 'shared/icons/logo';
import { Loader } from 'shared/icons/loader';
import { Button } from 'shared/components/buttons/Button';
import { useInterval } from 'shared/hooks/useInterval';
import profileActions from '../../profile/store/profileActions';
import { profileSelector } from '../../profile/store/profileSelectors';
import Styled from './Welcome.style';

const Welcome: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector(profileSelector);
  const { logout, user } = useContext(AuthContext);
  const [emailVerified, setEmailVerified] = useState(auth().currentUser?.emailVerified);

  useInterval(() => {
    auth()
      .currentUser?.reload()
      .then(() => auth().currentUser?.emailVerified)
      .then(setEmailVerified);
  }, 2000);

  useEffect(() => {
    user && dispatch(profileActions.loadProfile.request(user.uid));
  }, [user]);

  useEffect(() => {
    // ?? TODO, test and check reach-router 6
    const location = window.location;
    if (location.pathname !== '/') {
      location.pathname = '/';
    }
  }, []);

  const handleContinue = (): void => {
    const redirect = window.sessionStorage.getItem('returnUrl');
    window.sessionStorage.removeItem('returnUrl');
    redirect
      ? window.location.replace(window.location.origin + redirect)
      : window.location.reload();
  };

  return (
    <Styled.Page>
      <Logo />
      {userProfile ? (
        <>
          <Styled.Avatar
            size={120}
            src={userProfile?.profileImagePath}
            position={userProfile?.position}
          />
          <h4>{userProfile?.fullName}</h4>
          <h1>Congratulations Welcome to the movement.</h1>
          {emailVerified ? (
            <Button onClick={handleContinue}>Let&apos;s do this!</Button>
          ) : (
            <Styled.Footer>
              We’ve sent you a verification email. You must verify your email before you can
              proceed.
            </Styled.Footer>
          )}
        </>
      ) : (
        <div style={{ marginTop: '10vh', textAlign: 'center' }}>
          <h3>Loading user data...</h3>
          <Loader />
        </div>
      )}
    </Styled.Page>
  );
};

export default Welcome;
