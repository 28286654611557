import React from 'react';
import styled from 'styled-components/macro';
import { Modal as _Modal } from 'shared/components/modal/Modal';
import { Colours } from 'shared/styles/Colours';
import { LogoLarge } from 'shared/icons/logo-large';
import { IcoClose } from 'shared/icons/close';
import { IconButton } from 'shared/components/Buttons';

const Modal = styled(_Modal)`
  height: 100%;
`;

const ScrollPane = styled.div`
  color: ${Colours.tertiaryText};
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem 5rem;
  overflow: scroll;
  width: 100%;

  scrollbar-color: #ffffff #000000;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    background-color: #ffffff;
    height: 9px;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000000;
    margin: 2px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: min(550px, 100vw);
  margin: 0 auto;

  svg {
    flex-shrink: 0;
  }

  h2 {
    margin: 3rem 0 1rem;
  }
`;

const Footer = styled.div`
  background-color: white;
  color: ${Colours.tertiaryText};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 4rem;
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const FullScreenModal: React.FC<Props> = ({ isOpen, onClose, children }) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} overlayStyle={{ backgroundColor: 'white' }}>
      <ScrollPane>
        <Content>
          <LogoLarge />
          {children}
        </Content>
      </ScrollPane>
      <Footer>
        <IconButton onClick={onClose}>
          <IcoClose />
        </IconButton>
      </Footer>
    </Modal>
  );
};
