import React from 'react';

export const IcoClose: React.FC = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M39 20C39 30.4934 30.4934 39 20 39C9.50659 39 1 30.4934 1 20C1 9.50659 9.50659 1 20 1C30.4934 1 39 9.50659 39 20Z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <rect
      x="15.0502"
      y="13.6367"
      width="16"
      height="2.8"
      rx="1.4"
      transform="rotate(45 15.0502 13.6367)"
      fill="currentColor"
    />
    <rect
      x="24.9498"
      y="13.6367"
      width="2.8"
      height="16"
      rx="1.4"
      transform="rotate(45 24.9498 13.6367)"
      fill="currentColor"
    />
  </svg>
);
