import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { FieldError, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button } from 'shared/components/buttons/Button';
import { Input } from 'shared/components/input/Input';
import { ValidationError } from 'shared/components/validation-error/ValidationError';
import { Modal } from 'shared/components/modal/Modal';
import { Colours } from 'shared/styles/Colours';

const Form = styled.form`
  width: min(430px, 100vw);
  padding: 2rem 3rem;
  background-color: ${Colours.primaryBackground};
  border-radius: 0.5rem;
`;

const FormActions = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, 160px);
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (email: string) => void;
  message?: string;
}

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter a valid email address'),
});

export const ResetPasswordModal: React.FC<Props> = ({ isOpen, onClose, onSubmit, message }) => {
  const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(validationSchema) });
  const emailInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (emailInput.current) {
      emailInput.current.focus();
      register(emailInput.current);
    }
  }, [emailInput]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Form onSubmit={handleSubmit(({ email }) => onSubmit(email))}>
        <h3>Reset your password</h3>

        <Input label="Email" name="email" ref={emailInput} />
        <ValidationError message={(errors.email as FieldError)?.message} />
        <ValidationError message={message} />

        <FormActions>
          <Button type="submit">Reset</Button>
          <Button type="button" onClick={onClose}>
            Cancel
          </Button>
        </FormActions>
      </Form>
    </Modal>
  );
};
