import React, { useState } from 'react';
import { TimeoutButton } from './TimeoutButton';

export interface Props {
  onClick: () => void;
  timeout?: number;
}

export const UndoButton: React.FC<Props> = ({ onClick, timeout = 3000, children, ...props }) => {
  const [timerRunning, setTimerRunning] = useState<number>(0);

  const handleClick = (): void => {
    if (timerRunning) {
      window.clearTimeout(timerRunning);
      setTimerRunning(0);
    } else {
      setTimerRunning(
        window.setTimeout(() => {
          onClick();
          setTimerRunning(0);
        }, timeout)
      );
    }
  };

  return (
    <div onClick={handleClick} {...props}>
      {timerRunning ? (
        <TimeoutButton timeout={timeout} onClick={handleClick}>
          Undo
        </TimeoutButton>
      ) : (
        children
      )}
    </div>
  );
};
