import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, RouteComponentProps, navigate } from '@reach/router';
import { propOr } from 'ramda';
import { Avatar } from 'shared/components/Avatar';
import { AuthContext } from 'shared/services/auth/AuthContext';
import { BorderedButton } from 'shared/components/Buttons';
import { InfoBadge } from 'shared/components/InfoBadge';
import { StatsBadge } from 'shared/components/StatsBadge';
import { authdUserProfileSelector, profileSelector } from './store/profileSelectors';
import profileActions from './store/profileActions';
import { Squad } from './components/Squad';
import { MatchCard } from '../../shared/components/match-card/MatchCard';
import Styled from './Profile.styles';
import { isMatchLive } from '../../utils';

const Profile: React.FC<RouteComponentProps & { uid?: string; showsInDrawer?: boolean }> = ({
  uid,
  showsInDrawer = false,
  ...props
}) => {
  const { user } = useContext(AuthContext);
  const profile = useSelector(profileSelector);
  const loggedInUserProfile = useSelector(authdUserProfileSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    // if uid present this is other player profile, otherwise this is logged in user profile request
    const id = uid || user?.uid;
    id && setTimeout(() => dispatch(profileActions.loadProfile.request(id)), 0);
  }, [user, uid]);

  const isFollowing =
    loggedInUserProfile && profile?.followers.includes(loggedInUserProfile.userId);

  const handleFollow = (): void => {
    profile && dispatch(profileActions.followUser.request(profile.userId));
  };

  const handleUnfollow = (): void => {
    console.log('unfollow');
  };

  return profile ? (
    <Styled.Page {...props}>
      <Styled.Wrapper>
        <Avatar
          size={120}
          position={profile.position}
          src={profile?.profileImagePathThumbnail || profile?.profileImagePath}
        />
        <Styled.PlayerName>{profile?.fullName}</Styled.PlayerName>
        <Styled.PlayingStyle>
          {profile.playingStyle}. {profile.preferredFoot} footed
        </Styled.PlayingStyle>

        {user?.email === profile.email ? null : isFollowing ? (
          <Styled.Follow onClick={handleUnfollow}>Unfollow</Styled.Follow>
        ) : (
          <Styled.Follow onClick={handleFollow}>Follow</Styled.Follow>
        )}

        <Styled.Badges>
          <InfoBadge title="Squads" counter={profile.squads.length} />
          <InfoBadge title="Followers" counter={profile.followers.length} />
          <InfoBadge title="Following" counter={profile.following.length} />
        </Styled.Badges>

        <h6>
          <span>{profile.squads.length} My Squads</span>
          <BorderedButton>View All</BorderedButton>
        </h6>
        <Styled.SquadsWrapper>
          <Styled.Squads>
            {profile.squads.map((squad) => (
              <Squad
                key={squad.squadId}
                onClick={() => navigate(`/squad/${squad.squadUrl || squad.squadId}`)}
                src={squad.squadAvatar}
                name={squad.squadName}
                location={squad.squadLocation}
              />
            ))}
          </Styled.Squads>
        </Styled.SquadsWrapper>

        <h6>
          <span>Matches</span>
          <BorderedButton>View All</BorderedButton>
        </h6>
        <Styled.Matches showsInDrawer={showsInDrawer}>
          {profile.matches.length ? (
            profile.matches.map((match) => {
              return (
                <Link to={match.isLive ? match.liveMatchUrl! : `/match/${match.id}`} key={match.id}>
                  <MatchCard key={match.startAt._seconds} match={match} />
                </Link>
              );
            })
          ) : (
            <h3>No matches played.</h3>
          )}
        </Styled.Matches>

        <h6>
          <span>Matches</span>
        </h6>
        <Styled.Stats showsInDrawer={showsInDrawer}>
          {Object.entries({ played: 'Played', won: 'Won', drawn: 'Drawn', lost: 'Lost' }).map(
            ([itemName, title]) => (
              <StatsBadge
                key={itemName}
                counter={propOr(0)(itemName, profile.stats)}
                title={title}
              />
            )
          )}
        </Styled.Stats>

        <h6>
          <span>Stats</span>
        </h6>
        <Styled.Stats showsInDrawer={showsInDrawer}>
          {Object.entries({
            scored: 'Goals',
            assisted: 'Assists',
            cleansheet: 'Clean sheets',
            pom: 'Player of the match',
          }).map(([itemName, title]) => (
            <StatsBadge key={itemName} counter={propOr(0)(itemName, profile.stats)} title={title} />
          ))}
        </Styled.Stats>
      </Styled.Wrapper>
    </Styled.Page>
  ) : (
    <Styled.Page {...props}>
      <Styled.Loader />
    </Styled.Page>
  );
};

export default Profile;
