import React from 'react';

export const IcoPenSaved: React.FC = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <rect width="2" height="16" rx="1" fill="white" />
    <rect x="22" width="2" height="16" rx="1" fill="white" />
    <rect width="24" height="2" rx="1" fill="white" />
    <rect
      x="9.87891"
      y="8.46448"
      width="8"
      height="2"
      rx="1"
      transform="rotate(45 9.87891 8.46448)"
      fill="white"
    />
    <rect
      x="15.5352"
      y="9.87866"
      width="8"
      height="2"
      rx="1"
      transform="rotate(135 15.5352 9.87866)"
      fill="white"
    />
  </svg>
);
