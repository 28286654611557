import React, { useEffect, useState } from 'react';
import { Location, RouteComponentProps, Router } from '@reach/router';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import { AppState } from 'store/rootReducer';
import { UserProfile } from 'shared/types/UserProfile';
import { ProfileType } from 'shared/types/ProfileType.enum';
import actions from './store/registerActions';
import {
  isFetchingSelector,
  playingStyleOptions,
  progressSelector,
  registrationDataSelector,
} from './store/registerSelectors';
import { ProgressFooter } from './components/ProgressFooter';
import { ProgressMobile } from './components/ProgressMobile';
import { OptionGroup } from './OptionGroup';
import BasicsWrapper from './Basics/BasicsWrapper';
import Avatar from './Avatar/Avatar';
import PlayingPosition from './PlayingPosition/PlayingPosition';
import OurValues from './OurValues/OurValues';
import { Steps } from './Steps.enum';
import { ProfileTypeModal } from './Basics/components/ProfileTypeModal';
import Styled from './Register.styles';

const isMobile = window.screen.width < 768;

const AnimatedRoute: React.FC<RouteComponentProps & { component: any }> = ({
  component,
  ...props
}) => (
  <Styled.MotionWrapper
    initial={{ opacity: 0, transform: isMobile ? 'translateX(100%)' : '' }}
    animate={{ opacity: 1, transform: isMobile ? 'translateX(0px)' : '' }}
    exit={{ opacity: 0, transform: isMobile ? 'translateX(-100%)' : '' }}
  >
    {/* pass all reach-router props to actual component */ React.cloneElement(component, props)}
  </Styled.MotionWrapper>
);

export const Register: React.FC<RouteComponentProps> = ({ navigate, location }) => {
  const dispatch = useDispatch();
  const { activeStep, prevStep } = useSelector(progressSelector);
  const registrationData = useSelector(registrationDataSelector);
  const isFetching = useSelector(isFetchingSelector);
  const playingStyles = useSelector<AppState, string[]>(playingStyleOptions, shallowEqual);
  const [stepValues, setStepValues] = useState<Partial<UserProfile>>();
  const [profileTypeModalShows, setProfileTypeModalShows] = useState(false);
  // dont show 'our values' step in the progress bars
  const steps = Object.values(Steps).slice(0, -1);

  const handleStepSubmit = (values: Partial<UserProfile>): void => {
    dispatch(actions.stepCompleted(values));
  };

  const handleTypeSelect = (type: ProfileType): void => {
    // we have to wait for profile type modal to finish exit animating
    // before we can navigate to the next step, or we'll get errors
    // modal.onClosed is used to call passed onSubmit callback
    // TODO: remove exit animation?
    setProfileTypeModalShows(false);
    setStepValues((values: any) => ({ ...values!, profileType: type.toString() }));
  };

  const handleCancel = (): void => {
    navigate && navigate('/');
  };

  const goTo = (step: string): void => {
    dispatch(actions.gotoStep(step));
  };

  useEffect(() => {
    if (!activeStep) {
      return;
    }

    const stepUrl = activeStep.toLowerCase();
    if (navigate && location?.pathname.indexOf(stepUrl) == -1) {
      console.log('navigating to: ' + stepUrl);
      navigate(`./${stepUrl}`).then(() => {
        setStepValues(undefined);
      });
    }
  }, [activeStep, navigate]);

  return (
    <Styled.Page>
      <Location>
        {({ location }) => (
          <AnimatePresence>
            <Styled.AnimationWrapper>
              <ProgressMobile
                steps={steps}
                activeStep={activeStep}
                onCancel={handleCancel}
                onNext={() => {
                  activeStep === Steps.Basic
                    ? setProfileTypeModalShows(true)
                    : stepValues && handleStepSubmit(stepValues);
                }}
                isActiveStepValid={stepValues !== undefined}
              />

              <Router role="router" key={location.key} location={location}>
                <AnimatedRoute
                  path="basics"
                  key="basics"
                  default
                  component={
                    // 1st step is different, it has it's own submit button on non-mobile
                    // but also we need to show type modal when step is submitted so we
                    // can determine next step, unless user went back a step or more and
                    // already selected profile type
                    <BasicsWrapper
                      onSubmit={() =>
                        registrationData?.profileType
                          ? handleStepSubmit(stepValues!)
                          : setProfileTypeModalShows(true)
                      }
                      onValid={setStepValues}
                      values={registrationData}
                    />
                  }
                />

                <AnimatedRoute
                  path="gender"
                  key="gender"
                  component={
                    <OptionGroup
                      title="Gender"
                      options={['Male', 'Female']}
                      value={registrationData?.gender}
                      onChange={(value) => setStepValues({ gender: value })}
                    />
                  }
                />

                <AnimatedRoute
                  path="foot"
                  key="foot"
                  component={
                    <OptionGroup
                      title="Preferred foot"
                      value={registrationData?.preferredFoot}
                      columns={3}
                      options={['Left', 'Both', 'Right']}
                      onChange={(value) => setStepValues({ preferredFoot: value })}
                    />
                  }
                />

                <AnimatedRoute
                  path="position"
                  key="position"
                  component={
                    <PlayingPosition
                      value={registrationData?.position}
                      onChange={(value) => setStepValues({ position: value })}
                    />
                  }
                />

                <AnimatedRoute
                  path="style"
                  key="style"
                  component={
                    <OptionGroup
                      title="Playing style"
                      columns={2}
                      columnWidth="190px"
                      options={playingStyles}
                      value={registrationData?.playingStyle}
                      onChange={(value) => setStepValues({ playingStyle: value })}
                    />
                  }
                />

                <AnimatedRoute
                  path="avatar"
                  key="avatar"
                  component={
                    <Avatar
                      value={registrationData?.profileImageFile}
                      onChange={(value) => setStepValues({ profileImageFile: value })}
                    />
                  }
                />

                <AnimatedRoute
                  path="ourvalues"
                  key="ourvalues"
                  component={
                    <OurValues
                      isFetching={isFetching}
                      onCancel={handleCancel}
                      onAgree={() => dispatch(actions.createAccount.request())}
                    />
                  }
                />
              </Router>
              {activeStep !== Steps.Basic && activeStep !== Steps.OurValues && (
                <ProgressFooter
                  steps={steps}
                  activeStep={activeStep}
                  onCancel={handleCancel}
                  onNext={() => handleStepSubmit(stepValues || { profileImageFile: '' })}
                  onGoto={goTo}
                  isActiveStepValid={stepValues !== undefined || activeStep == Steps.Avatar}
                />
              )}
            </Styled.AnimationWrapper>
          </AnimatePresence>
        )}
      </Location>

      <ProfileTypeModal
        onSelect={handleTypeSelect}
        isOpen={profileTypeModalShows}
        onClosed={() => stepValues && handleStepSubmit(stepValues)}
      />
    </Styled.Page>
  );
};
