import { getType, Reducer } from 'typesafe-actions';
import { AnyAction } from 'redux';
import { mergeRight } from 'ramda';
import { Match } from 'shared/types/Match';
import actions from './matchActions';

export interface MatchState {
  matches: Record<string, Match>;
  selectedMatchId: string | null;
  isFetching: boolean;
  error: string | null;
}

export const initialMatchState: MatchState = {
  matches: {},
  selectedMatchId: null,
  isFetching: false,
  error: null,
};

const matchReducer: Reducer<MatchState, AnyAction> = (
  state = initialMatchState,
  action: AnyAction
) => {
  const { type, payload } = action;
  const updateState = mergeRight(state);

  switch (type) {
    case getType(actions.loadMatch.request): {
      return updateState<Partial<MatchState>>({
        isFetching: true,
        error: null,
      });
    }

    case getType(actions.loadMatch.success): {
      return updateState<Partial<MatchState>>({
        isFetching: false,
        selectedMatchId: payload.id,
        matches: { ...state.matches, [payload.id]: payload },
      });
    }

    case getType(actions.loadMatch.failure): {
      return updateState<Partial<MatchState>>({ isFetching: false, error: payload });
    }
  }

  return state;
};

export default matchReducer;
