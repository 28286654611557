import auth from '../log-in/api';
import * as yup from 'yup';

const validateUniqueEmail =
  (currentEmail?: string) =>
  async (value: string): Promise<boolean> => {
    if (!value || value == currentEmail) return true;
    const emailRegistered = await auth.checkEmailExists(value).catch((e) => {
      if (e.code === 'auth/network-request-failed') {
        return true;
      }

      return false;
    });

    return !emailRegistered;
  };

export const validationSchema = (currentEmail?: string): any =>
  yup.object({
    fullName: yup.string().required('Please enter your full name.'),
    email: yup
      .string()
      .required('Please enter a valid email address')
      .email('Please enter a valid email address')
      .test(
        'unique-email',
        'This email address is already registered',
        validateUniqueEmail(currentEmail)
      ),
    dob: yup.string().required('Please enter your date of birth'),
    location: yup.string().required('Please select your location.'),
  });
