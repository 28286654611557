import React, { useEffect, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from 'shared/components/input/Input';
import { Modal } from 'shared/components/modal/Modal';
import { ValidationError } from 'shared/components/validation-error/ValidationError';
import { Location } from '../Location/Location';
import { TermsOfUse } from './components/TermsOfUse';
import { PrivacyPolicy } from './components/PrrivacyPolicy';
import { FullScreenModal } from './components/FullScreenModal';
import { validationSchema } from './utils';
import Styled from './Basics.styles';

interface Props {
  onSubmit: () => void;
  onValid: (values: Record<string, any>) => void;
  values?: Record<string, any>;
}

const BasicsForm: React.FC<Props> = ({ onSubmit, onValid, values, children }) => {
  const { register, handleSubmit, errors, getValues, formState, trigger } = useForm({
    defaultValues: values,
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const [location, setLocation] = useState<string>(values?.location ?? '');
  const [locationModalShows, setLocationModalShows] = useState(false);
  const [modalContent, setModalContent] = useState<React.FC | null>(null);

  useEffect(() => {
    location && trigger('location');
  }, [location]);

  useEffect(() => {
    if (formState.isValid) {
      onValid(getValues());
    }
  }, [formState.isValid]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {children}

      <Input label="Full name:" name="fullName" ref={register} value={values?.fullName} />
      <ValidationError message={(errors.fullName as FieldError)?.message} />

      <Input label="Email:" name="email" ref={register} value={values?.email} />
      <ValidationError message={(errors.email as FieldError)?.message} />

      <Input label="DOB:" type="date" name="dob" ref={register} value={values?.dob} />
      <ValidationError message={(errors.dob as FieldError)?.message} />

      <Input
        label="Where are you based"
        name="location"
        autoComplete="off"
        ref={register}
        value={location}
        onFocus={() => setLocationModalShows(true)}
      />
      <ValidationError message={(errors.location as FieldError)?.message} />

      <Input
        label="Password:"
        type="password"
        name="password"
        autoComplete="new-password"
        ref={register}
        value={values?.password}
      />
      <ValidationError message={(errors.password as FieldError)?.message} />

      <Styled.TCWrapper>
        <input type="checkbox" name="tc" ref={register} />
        <label>
          Please tick this box if you are happy to agree to our{' '}
          <a onClick={() => setModalContent(TermsOfUse)}>Terms of Use</a> and{' '}
          <a onClick={() => setModalContent(PrivacyPolicy)}>Privacy Policy</a>.
        </label>
        <ValidationError message={(errors.tc as FieldError)?.message} />
      </Styled.TCWrapper>

      <Styled.SubmitButton type="submit">Next</Styled.SubmitButton>

      <FullScreenModal isOpen={modalContent !== null} onClose={() => setModalContent(null)}>
        {modalContent}
      </FullScreenModal>

      <Modal
        onClose={() => setLocationModalShows(false)}
        isOpen={locationModalShows}
        contentStyle={{ height: '100%' }}
      >
        <Location
          onCancel={() => setLocationModalShows(false)}
          onLocationSelected={(location) => {
            setLocation(location);
            setLocationModalShows(false);
          }}
        />
      </Modal>
    </form>
  );
};

export default BasicsForm;
