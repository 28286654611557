import * as React from 'react';

export const IcoPlus: React.FC = (props) => (
  <svg width={17} height={18} viewBox="0 0 17 18" fill="none" {...props}>
    <path
      d="M15.045 8.568H9.36v-5.66a.908.908 0 10-1.815 0v5.684H1.908A.892.892 0 001 9.5a.84.84 0 00.908.86h5.684v5.685a.892.892 0 00.908.908.878.878 0 00.908-.908V10.36h5.685A.892.892 0 0016 9.452a.913.913 0 00-.955-.884z"
      fill="#070b0f"
      stroke="#070b0f"
      strokeWidth={2}
    />
  </svg>
);
