import React from 'react';
import styled from 'styled-components/macro';
import { Colours } from 'shared/styles/Colours';
import { Modal as _Modal } from 'shared/components/modal/Modal';
import { Button as _Button } from 'shared/components/buttons/Button';
import { ProfileType } from 'shared/types/ProfileType.enum';

const Modal = styled(_Modal)`
  background-color: ${Colours.modalBackground};
  width: min(420px, 100vw);
  border-radius: 0.5rem;
  padding: 2rem 3rem 5rem;
  align-items: center;

  h4 {
    text-align: center;
    margin-bottom: 4rem;
  }

  hr {
    margin: 2rem 0;
  }

  p {
    font-size: 0.75rem;
    margin-top: 5px;
  }
`;

const Button = styled(_Button)`
  width: 184px;
`;

interface Props {
  isOpen: boolean;
  onSelect: (type: ProfileType) => void;
  onClosed: () => void;
}

export const ProfileTypeModal: React.FC<Props> = ({ onSelect, onClosed, isOpen }) => (
  <Modal isOpen={isOpen} onExitComplete={onClosed}>
    <h4>
      What type of profile <br /> would you like to create?
    </h4>

    <Button onClick={() => onSelect(ProfileType.COMPLEX)}>Football profile</Button>
    <p>Play matches, support and/or manage teams</p>

    <hr />

    <Button secondary onClick={() => onSelect(ProfileType.SIMPLE)}>
      Supporter profile
    </Button>
    <p>Support and/or manage teams</p>
  </Modal>
);
