import styled from 'styled-components/macro';
import { device } from 'shared/styles/MediaQuery';
import { Avatar as _Avatar } from 'shared/components/Avatar';
import { Colours } from 'shared/styles/Colours';

const Page = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding-top: 15vh;

  ${device.tablet} {
    padding-top: 20vh;
  }

  h1 {
    line-height: 1.1em;
    margin: 2em 0 1em;
    max-width: 500px;
    text-align: center;
  }
`;

const Avatar = styled(_Avatar)`
  margin: 5rem 0 2rem;
`;

const Footer = styled.div`
  background-color: ${Colours.secondaryElement};
  margin-top: auto;
  padding: 1.5rem;
  text-align: center;
  width: 100%;
`;

export default {
  Page,
  Avatar,
  Footer,
};
