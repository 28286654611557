import styled from 'styled-components/macro';
import { motion } from 'framer-motion';

const Wrapper = styled(motion.div)`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;

  form {
    width: 400px;
  }
`;

export default {
  Wrapper,
};
