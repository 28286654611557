import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { Tab } from 'shared/components/tabs/tabs';
import actions from './store/matchActions';
import { matchSelector } from './store/matchSelectors';
import { Event } from './components/Event';
import Styled from './Match.styles';
import { unfold, zip } from 'ramda';
import uiActions from '../../store/uiActions';

dayjs.extend(duration);

const Match: React.FC<RouteComponentProps & { id?: string }> = ({ navigate, id, ...props }) => {
  const match = useSelector(matchSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    id && dispatch(actions.loadMatch.request(id));
  }, [id]);

  if (match === null) {
    return <Styled.Loader />;
  }

  const matchDate = dayjs.unix(match.startAt._seconds).format('MM ddd, MMM YYYY. HH:mm ');
  const duration = dayjs.duration(Math.ceil(match.time), 'seconds');

  const { homeTeamPlayers, awayTeamPlayers } = match;

  const maxTeamPlayers = Math.max(homeTeamPlayers.length, awayTeamPlayers.length);
  const fillBlanks =
    (team: any[]) =>
    (n: number): false | [any, number] =>
      n < maxTeamPlayers && [team[n] || null, n + 1];
  const lineUps = zip(
    unfold(fillBlanks(homeTeamPlayers), 0),
    unfold(fillBlanks(awayTeamPlayers), 0)
  );

  const handleProfileClick = (uid?: string): void => {
    uid &&
      dispatch(
        uiActions.sidebarChange({ component: 'playerProfile', props: { uid, showsInDrawer: true } })
      );
  };

  const formatRanking = new Intl.NumberFormat('en-GB', { maximumSignificantDigits: 2 }).format;

  return (
    <Styled.Wrapper {...props}>
      <Styled.Logo />
      <h5>
        {match.title} {match.settings?.length ? `(${match.settings?.length}min` : null}
      </h5>
      <Styled.Date>{matchDate}</Styled.Date>
      <Styled.Duration>
        {duration.hours() ? `${duration.hours()}` : 0}:{duration.minutes()}.{duration.seconds()}
      </Styled.Duration>
      <Styled.Result>
        <Styled.Score>
          {match.score0 ?? '0'}
          <span>{match.homeTeamName}</span>
        </Styled.Score>
        <Styled.Score>
          {match.score1 ?? '0'}
          <span>{match.awayTeamName}</span>
        </Styled.Score>
      </Styled.Result>
      <Styled.Tabs>
        <Tab header={<span>Match Report</span>}>
          {match
            .events!.sort((a, b) => (a.time > b.time ? 1 : -1))
            .map((event, idx) => (
              <Event key={idx} event={event} />
            ))}
        </Tab>
        <Tab header={<span>Line-ups</span>}>
          {lineUps.map(([homePlayer, awayPlayer]) => (
            <Styled.LineUpRow key={homePlayer}>
              <span className="player" onClick={() => handleProfileClick(homePlayer?.userId)}>
                {homePlayer?.fullName}
                {homePlayer && match.ratingStatus === 'finished' ? (
                  <span className="rating">{formatRanking(homePlayer.rating)}</span>
                ) : null}
              </span>
              <span> - &nbsp; - </span>
              <span className="player" onClick={() => handleProfileClick(awayPlayer?.userId)}>
                {awayPlayer?.fullName}
                {awayPlayer && match.ratingStatus === 'finished' ? (
                  <span className="rating">{formatRanking(awayPlayer.rating)}</span>
                ) : null}
              </span>
            </Styled.LineUpRow>
          ))}
        </Tab>
      </Styled.Tabs>
    </Styled.Wrapper>
  );
};

export default Match;
