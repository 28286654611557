import React from 'react';

export const IcoCheckCircle: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <rect width="32" height="32" rx="16" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2575 17.6822C13.6793 17.1481 12.7447 16.1695 12.1221 15.5468C11.4994 14.9687 11.0989 15.4577 10.3425 16.2586C9.49702 17.1041 10.3425 17.5485 11.5439 18.7499L13.7239 20.9299C14.5248 21.6417 15.1921 20.574 16.2597 19.5508L20.6197 15.1464C21.9988 13.8119 22.3101 13.8119 21.1979 12.7442C20.2193 11.721 20.2638 11.721 18.7066 13.3223C17.9948 13.9895 14.4804 17.5485 14.2576 17.6823L14.2575 17.6822Z"
      fill="black"
    />
  </svg>
);
