import React, { useRef, useState } from 'react';
import { Avatar as AvatarImg } from 'shared/components/Avatar';

import Styled from './ImageInput.styles';

interface Props {
  onChange?: (file: string) => void;
  value?: string;
}

export const ImageInput: React.FC<Props> = ({ onChange, value = '/images/avatar.svg' }) => {
  const [image, setImage] = useState<string | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = (event: React.SyntheticEvent): void => inputRef.current?.click();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      if (event.target?.result) {
        setImage(event.target.result as string);
        onChange && onChange(event.target.result as string);
      }
    };

    if (event.target.files?.length) {
      fileReader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <Styled.Wrapper onClick={handleClick}>
      <Styled.EditIcon />
      <input type="file" accept="image/*" ref={inputRef} onChange={handleChange} />
      <AvatarImg size={120} src={image || value} />
      {
        // we should add capture option for mobile devices
        // <Styled.ImageInput>
        //   <input type="file" accept="image/*" capture="user" />
        // </Styled.ImageInput>
      }
    </Styled.Wrapper>
  );
};
