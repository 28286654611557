import React from 'react';
import styled from 'styled-components/macro';
import { PlayingPosition } from '../types/PlayingPosition';
import { PositionBadge as _PositionBadge } from './PositionBadge';

const Wrapper = styled.div`
  position: relative;
`;

const ProfilePic = styled.img<{ src?: string; size?: number }>`
  width: ${(props) => props.size || 56}px;
  height: ${(props) => props.size || 56}px;
  border-radius: 50%;
  background-color: transparent;
  object-position: top center;
  object-fit: cover;
`;

const PositionBadge = styled(_PositionBadge)<{ scale: number }>`
  position: absolute;
  bottom: -10px;
  left: -10px;
  transform: scale(${(props) => props.scale});
  transform-origin: 30% 70%;
`;

export interface Props {
  src?: string;
  size?: number;
  position?: PlayingPosition;
}

export const Avatar: React.FC<Props> = ({
  src = '/images/avatar.svg',
  position,
  size = 56,
  ...props
}) => {
  const scale = size / 110;

  return (
    <Wrapper {...props}>
      <ProfilePic src={src?.length > 0 ? src : '/images/avatar.svg'} size={size} />
      {position && <PositionBadge position={position} scale={scale} />}
    </Wrapper>
  );
};
