import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { PlayingPosition as _PP } from 'shared/types/PlayingPosition';
import { Position } from 'shared/components/position/Position';
import { Header } from '../components/Header';
import Styled from './PlayingPosition.style';

interface Props {
  onChange: (value: _PP) => void;
  value?: _PP;
}

const PlayingPosition: React.FC<RouteComponentProps & Props> = ({ onChange, value }) => {
  return (
    <Styled.Page>
      <Header title="Preferred position" />
      <Position value={value} onChange={onChange} />
    </Styled.Page>
  );
};

export default PlayingPosition;
