import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Router } from '@reach/router';
import Login from 'modules/log-in/Login';
import Profile from 'modules/profile/Profile';
import Welcome from 'modules/register/Welcome/Welcome';
import Match from 'modules/match/Match';
import LiveMatch from 'modules/match/LiveMatch';
import SquadProfile from 'modules/squad-profile/SquadProfile';
import { Register } from 'modules/register/Register';
import { EditProfile } from '../modules/edit-profile/EditProfile';
import { userNotifications } from 'modules/profile/store/profileSelectors';
import { GlobalStyle } from 'shared/styles/GlobalStyle';
import { AuthGuard } from 'shared/services/auth/AuthGuard';
import { AuthContext } from 'shared/services/auth/AuthContext';
import { LogoLarge } from 'shared/icons/logo-large';
import { Sidebar } from 'shared/components/sidebar/sidebar';
import Menu from 'shared/components/sidebar/menu';
import Notifications from 'shared/components/sidebar/notifications';
import uiActions from '../store/uiActions';
import { AppState } from '../store/rootReducer';
import { Navbar } from '../shared/components/navbar/Navbar';
import { UserProfile } from '../shared/types/UserProfile';
import profileActions from '../modules/profile/store/profileActions';
import { AppNotification } from './AppNotifications';
import Styled from './App.styles';
import { RatePlayers } from '../modules/rate-players/RatePlayers';
import { AddMatch } from '../modules/add-match/AddMatch';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const sidebarState = useSelector((state: AppState) => state.ui.sidebar);
  const notifications = useSelector(userNotifications);
  const { logout, user, token } = useContext(AuthContext);

  const handleSidebarToggle = (): void => {
    dispatch(uiActions.sidebarChange({ component: null, props: {} }));
  };

  const handleLogout = (): void => {
    handleSidebarToggle();
    logout();
  };

  const notificationsCount = notifications.reduce((acc, item) => acc + (item.isRead ? 0 : 1), 0);

  useEffect(() => {
    // wait for token before trying to load notifications
    if (!token) return;

    dispatch(uiActions.userAuthStateChanged(user));
    if (!user) {
      axios
        .get<UserProfile>(`/api/users/getByAuth`)
        .then((response) => response.data)
        .then((profile) => dispatch(profileActions.loadProfile.success(profile)))
        .catch(console.log);
    }
  }, [user, token]);

  return (
    <Styled.Wrapper>
      <GlobalStyle />
      <Sidebar onClose={handleSidebarToggle}>
        {sidebarState.component &&
          {
            menu: <Menu onLogout={handleLogout} />,
            notifications: <Notifications notifications={notifications} />,
            playerProfile: sidebarState.props && (
              <Profile
                uid={sidebarState.props.uid}
                showsInDrawer={sidebarState.props.showsInDrawer}
              />
            ),
          }[sidebarState.component]}
      </Sidebar>
      <GlobalStyle />
      <AppNotification />
      <AuthGuard>
        {({ isInitialising, token, isAnonymous, emailVerified }) => {
          if (isInitialising || !token) {
            return (
              <Styled.Splash>
                <LogoLarge />
              </Styled.Splash>
            );
          }

          // grab token and reconfigure axios to send it with every request
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

          return isAnonymous ? (
            <Router role="router">
              <Login path="/" default />
              <Register path="register/*" />
              <LiveMatch path="/squad/:idOrName/live" />
              <RatePlayers path="/match/:matchId/player-rating" />
              <SquadProfile path="/squad/*" />
              <Profile path="/profile/:uid" />
            </Router>
          ) : !emailVerified ? (
            <Welcome path="/" default />
          ) : (
            <>
              <Navbar notifications={notificationsCount} />
              <Router role="router" style={{ paddingTop: '6rem', overflow: 'hidden' }}>
                <Profile path="/" default />
                <Profile path="/profile/:uid" />
                <EditProfile path="/edit-profile" />
                <SquadProfile path="/squad/*" />
                <RatePlayers path="/match/:matchId/player-rating" />
                <Match path="/match/:id" />
                <LiveMatch path="/squad/:idOrName/live" />
                <AddMatch path="/add-match" />
              </Router>
            </>
          );
        }}
      </AuthGuard>
    </Styled.Wrapper>
  );
};

export default App;
