import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { getType } from 'typesafe-actions';
import { acceptInvite, fetchProfile, rejectInvite } from '../api';
import actions from './squadActions';

function* loadSquadProfile(action: AnyAction): Generator {
  try {
    const response = yield fetchProfile(action.payload).then((response) => response.data);
    yield put(actions.loadSquadProfile.success(response as any));
  } catch (e) {
    yield put(actions.loadSquadProfile.failure(e));
  }
}

function* acceptSquadInvite(action: AnyAction): Generator {
  try {
    yield acceptInvite(action.payload);
    yield put(actions.acceptSquadInvite.success(action.payload));
  } catch (e) {
    yield put(actions.acceptSquadInvite.failure(e));
  }
}

function* rejectSquadInvite(action: AnyAction): Generator {
  try {
    yield rejectInvite(action.payload);
    yield put(actions.rejectSquadInvite.success(action.payload));
  } catch (e) {
    yield put(actions.rejectSquadInvite.failure(e));
  }
}

function* watchAPIRequests(): Generator {
  yield takeLatest(getType(actions.loadSquadProfile.request), loadSquadProfile);
  yield takeLatest(getType(actions.acceptSquadInvite.request), acceptSquadInvite);
  yield takeLatest(getType(actions.rejectSquadInvite.request), rejectSquadInvite);
}

export function* squadSaga(): Generator {
  yield all([fork(watchAPIRequests)]);
}
