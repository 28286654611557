export const positionToStyles = [
  {
    positions: ['S', 'F'],
    playingStyles: [
      'All Action',
      'All Rounder',
      'Clinical Finisher',
      'False Number 9',
      'Goal Poacher',
      'Playmaker',
      'The Big Man',
    ],
  },
  {
    positions: ['AM', 'LW', 'RW', 'LM', 'CM', 'RM', 'LWB', 'DM', 'RWB'],
    playingStyles: [
      'All Action',
      'All Rounder',
      'Box-to-Box Midfielder',
      'Cultured Midfielder',
      'Dog of War',
      'Playmaker',
      'Silky Midfielder',
      'Winger',
      'Wide Midfielder',
    ],
  },
  {
    positions: ['LB', 'LCB', 'CB', 'RCB', 'RB', 'SW'],
    playingStyles: [
      'All Action',
      'All Rounder',
      'Ball Playing Defender',
      'Commanding',
      'No-Nonsense',
      'Rattler',
    ],
  },
];

export const playingStylesForPosition = (position: string): string[] =>
  positionToStyles.find((group) => group.positions.includes(position))!.playingStyles;
