import React from 'react';
import { User } from 'firebase';
import { AuthContext } from './AuthContext';

export const AuthGuard: React.FC<{
  children(state: {
    isInitialising: boolean;
    isAnonymous: boolean;
    emailVerified: boolean;
    user: User | null;
    token: string | null;
  }): React.ReactElement;
}> = ({ children }) => {
  const { emailVerified, token, isInitialising, user } = React.useContext(AuthContext);
  const isAnonymous = user ? user.isAnonymous : true;

  return children({ isInitialising, isAnonymous, emailVerified, user, token });
};
