import React, { useEffect, useState } from 'react';
import { auth, User } from 'firebase';
import { AuthContext } from './AuthContext';
import Firebase from '../../../firebase.config';

export interface Props {
  firebase: Firebase;
}

export const AuthProvider: React.FC<Props> = ({ firebase, children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [isInitialising, setIsInitialising] = useState(true);
  const logout = (): Promise<void> => auth().signOut();

  useEffect(() => {
    window.sessionStorage.setItem('returnUrl', window.location.pathname);
    const unsubscribe = auth().onAuthStateChanged((user: firebase.User | null) => {
      isInitialising && setIsInitialising(false);
      user
        ? user
            .getIdToken()
            .then(setToken)
            .finally(() => !user?.isAnonymous && setUser(user))
        : setUser(null);
    });

    const user = Object.keys(window.sessionStorage).find((item) =>
      item.startsWith('firebase:authUser')
    );

    if (!user) {
      firebase
        .signInAnonymously()
        .then((result) => result.user?.getIdToken())
        .then((token) => token && setToken(token));
    }

    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        emailVerified: user?.emailVerified || false,
        token,
        isInitialising,
        firebase: firebase.app,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
