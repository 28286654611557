import { createGlobalStyle, css } from 'styled-components/macro';
import { device } from './MediaQuery';
import { Colours } from './Colours';

export const CustomScrollbars = css`
  scrollbar-color: 000000 #ffffffaa;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    background-color: #000000;
    height: 9px;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ffffffaa;
    margin: 2px;

    &:hover {
      background-color: #ffffff;
    }
  }
`;

const CustomCheckbox = css`
  input[type='checkbox'] {
    all: unset;
    border: 1.5px solid white;
    display: inline-block;
    min-width: 23px;
    height: 23px;
    margin-right: 1rem;
    transition: background-color 150ms ease-in;

    &:after {
      width: 23px;
      line-height: 23px;
      text-align: center;
      display: inline-block;
    }

    @media (hover: hover) {
      &:hover:after {
        content: url('data:image/svg+xml; utf8,
          <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
              fill-rule="evenodd" 
              clip-rule="evenodd"
              fill="white" 
              d="M12 1.31156C12 0.58748 11.3802 0 10.6151 0C10.1817 0 9.79529 0.188911 9.54112 0.483863L9.5406 0.483369L4.54943 6.28884L2.40211 4.08659L2.40107 4.08757C2.14795 3.82813 1.787 3.66486 1.38493 3.66486C0.619823 3.66437 0 4.25132 0 4.97591C0 5.31921 0.140626 5.63044 0.368225 5.86424L0.367183 5.86523L3.59783 9.17828L3.59887 9.17729C3.85199 9.43673 4.21294 9.6 4.61501 9.6C5.04834 9.6 5.4348 9.41109 5.68897 9.11614L5.68949 9.11663L11.6895 2.13925L11.6889 2.13876C11.8832 1.91334 11.9999 1.6253 11.9999 1.3116L12 1.31156Z" 
            />
          </svg>
        ');
      }
    }

    &:checked {
      background-color: ${Colours.secondaryBackground};

      &:after {
        content: url('data:image/svg+xml; utf8, 
          <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
              fill-rule="evenodd" 
              clip-rule="evenodd" 
              d="M12 1.31156C12 0.58748 11.3802 0 10.6151 0C10.1817 0 9.79529 0.188911 9.54112 0.483863L9.5406 0.483369L4.54943 6.28884L2.40211 4.08659L2.40107 4.08757C2.14795 3.82813 1.787 3.66486 1.38493 3.66486C0.619823 3.66437 0 4.25132 0 4.97591C0 5.31921 0.140626 5.63044 0.368225 5.86424L0.367183 5.86523L3.59783 9.17828L3.59887 9.17729C3.85199 9.43673 4.21294 9.6 4.61501 9.6C5.04834 9.6 5.4348 9.41109 5.68897 9.11614L5.68949 9.11663L11.6895 2.13925L11.6889 2.13876C11.8832 1.91334 11.9999 1.6253 11.9999 1.3116L12 1.31156Z"
              fill="currentColor"
             />
           </svg>');
      }
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
	*,
	*:before,
	*:after {
		box-sizing: border-box;
	}

	html {
	  background-color: ${Colours.primaryBackground};
		height: 100%;
		margin: 0;
		padding: 0;
		width: 100%;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}

	body {
		color: ${Colours.primaryText};
		font-size: 16px;
		font-weight: 500;
		font-family: 'Montserrat', sans-serif;
		height: 100%;
		line-height: 1.5;
		margin: 0;
		padding: 0;
    position: fixed; /* why iOS! */
		width: 100%;
		-webkit-font-smoothing: antialiased;
		
    ${device.mobile} {
      border-top: 5px solid forestgreen;
    }
    
    ${device.tablet} {
      border-top: 5px solid royalblue;
    }
	}
	
	#root {
	  max-width: 100%;
	  height: 100%;
	  display: flex;
	  overflow: hidden;
	}

	a {
		color: inherit;
		text-decoration: none;
	}
	
	ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

	h1, h2, h3, h4, h5, h6 {
		margin: 0 0 1rem 0;
	}

	h1 {
	  font-size: 2rem;
		font-weight: 500;
		letter-spacing: -0.2px;
		margin-bottom: 1rem;
		
		${device.tablet} {
		  font-size: 2.5rem;  
		}
	}

	h2 {
		font-size: 1.5rem;
		font-weight: 500;
		letter-spacing: -0.2px;
		
		${device.tablet} {
      font-size: 2rem;  
    }
		
	}

	h3 {
		font-size: 1rem;
    font-weight: 500;
		letter-spacing: -0.2px;
		
		${device.tablet} {
      font-size: 1.5rem;  
    }
	}

	h4 {
		font-size: 1.4rem;
		}

	h5 {
		font-size: 1.25rem;
	}

	h6 {
		font-size: 1rem;
	}
	
	hr {
	  border: thin solid ${Colours.divider};
	  border-top: none;
	  width: 100%;
	}

	p {
		margin: 0;
		letter-spacing: -0.24px;
		line-height: 1.5rem;
	}

	table {
		border-collapse: collapse;
	}

	small {
		font-size: 0.7em;
	}

  input,
  button,
  textarea,
  select {
    font: inherit;
  }
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background-color: ${Colours.primaryBackground};
    color: ${Colours.primaryText};
    -webkit-text-fill-color: ${Colours.primaryText};
    -webkit-box-shadow: 0 0 0 1000px ${Colours.primaryBackground} inset;
    transition: background-color 5000s ease-in-out 0s;
  }
  
  /* Reach Router */
  [role="router"] {
	  display: flex;
	  flex: 1;
	  min-height: 0;
	  //max-height: 100%; /* firefox expands wrapper when using height */
	}
  
  ${CustomScrollbars}
  ${CustomCheckbox}
  
  && .react-toast-notifications__container {
    .react-toast-notifications__toast__icon-wrapper {
      display: none;
    }
    
    .react-toast-notifications__toast__dismiss-button {
      color: white;
    }
    
    .react-toast-notifications__toast {
      background-color: ${Colours.secondaryElement};
    }
  }
`;
