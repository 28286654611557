import { auth } from 'firebase';

const checkEmailExists = async (email: string): Promise<boolean> => {
  const methods = await auth().fetchSignInMethodsForEmail(email);
  return methods.length > 0;
};

const login = (email: string, password: string): Promise<auth.UserCredential> =>
  auth().signInWithEmailAndPassword(email, password);

const requestPasswordReset = (email: string): Promise<void> => {
  return auth().sendPasswordResetEmail(email);
};

export default {
  checkEmailExists,
  login,
  requestPasswordReset,
};
