import { getType, Reducer } from 'typesafe-actions';
import { AnyAction } from 'redux';
import { mergeRight } from 'ramda';
import { Squad } from 'shared/types/Squad';
import actions from './squadActions';

export interface SquadState {
  squad: Squad | null;
  isFetching: boolean;
  error: string | null;
}

export const initialProfileState: SquadState = {
  squad: null,
  isFetching: false,
  error: null,
};

const squadReducer: Reducer<SquadState, AnyAction> = (
  state = initialProfileState,
  action: AnyAction
) => {
  const { type, payload } = action;
  const updateState = mergeRight(state);

  switch (type) {
    case getType(actions.loadSquadProfile.request):
    case getType(actions.acceptSquadInvite.request): {
      return updateState<Partial<SquadState>>({ isFetching: true, error: null });
    }

    case getType(actions.loadSquadProfile.failure):
    case getType(actions.acceptSquadInvite.failure): {
      return updateState<Partial<SquadState>>({ isFetching: false, error: payload });
    }

    case getType(actions.loadSquadProfile.success): {
      return updateState<Partial<SquadState>>({ isFetching: false, squad: payload });
    }

    case getType(actions.acceptSquadInvite.success): {
      return updateState<Partial<SquadState>>({
        isFetching: false,
        squad: state.squad && {
          ...state.squad,
          squadInvite: null,
        },
      });
    }
  }

  return state;
};

export default squadReducer;
