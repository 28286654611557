import React, { useState } from 'react';
import { Button } from 'shared/components/buttons/Button';
import { Loader } from 'shared/icons/loader';
import Styled from './OurValues.styles';

interface Props {
  onAgree: () => void;
  onCancel: () => void;
  isFetching: boolean;
}
const OurValues: React.FC<Props> = ({ onAgree, onCancel, isFetching }) => {
  const [showsModal, setShowsModal] = useState(false);

  return (
    <Styled.Page>
      <Styled.Wrapper>
        <Styled.Logo />
        <h1>
          Before you can join our movement we need you to agree to abide by three key principles
          that are core to who we are
        </h1>
        <ol>
          <li>Treat everyone with respect, dignity and value</li>
          <li>Seek to encourage others and create a community where everyone feels welcomed</li>
          <li>Play football with a smile</li>
        </ol>
        <Styled.ButtonGroup>
          <Button onClick={onAgree} disabled={isFetching}>
            I agree
            {isFetching && <Loader />}
          </Button>
          <Button secondary onClick={() => setShowsModal(true)} disabled={isFetching}>
            Sorry, not for me
          </Button>
        </Styled.ButtonGroup>

        <Styled.Modal isOpen={showsModal}>
          <h3>Please confirm that this is not for you</h3>
          <Button onClick={onCancel}>Not for me</Button>
          <Button secondary onClick={() => setShowsModal(false)}>
            Cancel
          </Button>
        </Styled.Modal>
      </Styled.Wrapper>
    </Styled.Page>
  );
};

export default OurValues;
