import React from 'react';
import styled from 'styled-components/macro';
import { IcoBack } from 'shared/icons/back';
import { Colours } from '../../styles/Colours';
import { IconButton } from '../Buttons';

const SidebarWrapper = styled.div<{ isVisible: boolean }>`
  background-color: ${Colours.tertiaryText};
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 360px;
  padding: 5rem 1rem 1rem;
  position: absolute;
  right: 0;
  transform: translateX(${(props) => (props.isVisible ? '0%' : '100%')});
  transition: transform 300ms cubic-bezier(0.86, 0, 0.07, 1);
  width: 35%;
  z-index: 100;

  ${IconButton} {
    padding: 0.5rem;
    position: absolute;
    top: 16px;
    left: 16px;
    transform: rotateY(180deg);
  }
`;

export const Sidebar: React.FC<{ onClose: () => void }> = ({ children, onClose, ...props }) => {
  return (
    <SidebarWrapper isVisible={React.Children.count(children) !== 0} {...props}>
      <IconButton onClick={onClose}>
        <IcoBack />
      </IconButton>
      {children}
    </SidebarWrapper>
  );
};
