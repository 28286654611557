import styled from 'styled-components/macro';
import { device } from 'shared/styles/MediaQuery';
import { Colours } from 'shared/styles/Colours';
import { Button } from 'shared/components/buttons/Button';

const Hint = styled.p`
  color: ${Colours.secondaryText};
  margin: 1rem 0 2rem;

  a {
    color: ${Colours.primaryText};
  }
`;

const Page = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;

  ${device.tablet} {
    align-items: flex-start;
  }

  form {
    display: flex;
    flex: 1 0 auto; /* safari - or form will collapse */
    flex-direction: column;
    max-width: 350px;

    ${device.tablet} {
      h1,
      ${Hint} {
        display: none;
      }
    }
  }
`;

const TCWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0; /* safari */
  font-size: 0.75rem;
  flex-wrap: wrap;
  margin: 1rem 0 4rem;

  label {
    margin-bottom: 1rem;
    max-width: 80%;
  }

  && input {
    margin-bottom: 1rem;
  }

  a {
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline;
  }
`;

const CoverImage = styled.div`
  align-items: flex-end;
  background-image: linear-gradient(90deg, rgba(7, 11, 15, 0) 0%, rgb(8, 11, 14) 100%),
    url('/images/register_bg.jpg');
  background-position: 50%;
  background-size: cover;
  display: none;
  height: 100%;
  overflow: hidden;
  padding-top: 30vh;
  width: 50%;

  ${device.tablet} {
    display: flex;
    flex-direction: column;

    h1,
    ${Hint} {
      width: 220px;
    }

    img {
      display: none;
    }
  }
`;

const ScrollPane = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding-left: 1rem;
  padding-top: 15vh;

  ${device.tablet} {
    height: 100%;
    padding-top: 20vh;
  }
`;

const Logo = styled.img`
  align-self: center;
  margin-bottom: 3rem;

  ${device.tablet} {
    align-self: start;
    margin-bottom: 1rem;
  }
`;

const SubmitButton = styled(Button)`
  display: none;

  ${device.tablet} {
    display: unset;
  }
`;

const Footer = styled.p`
  color: ${Colours.secondaryText};
  margin-top: auto;
  padding: 2rem 0;
  font-size: 0.8rem;
`;

export default {
  Page,
  Hint,
  CoverImage,
  ScrollPane,
  TCWrapper,
  Footer,
  Logo,
  SubmitButton,
};
