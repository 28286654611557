import styled from 'styled-components/macro';
import { Colours } from '../../styles/Colours';

const Wrapper = styled.div`
  border-bottom: 1px solid ${Colours.divider};
  font-size: inherit;
  margin-bottom: 1rem;
  padding-top: 1.3rem;
  position: relative;
  transition: border-bottom-color 150ms cubic-bezier(0.39, 0.575, 0.565, 1);

  &:hover {
    border-bottom-color: #fff;
  }
`;

const Label = styled.label<{ active: boolean }>`
  bottom: 20px;
  color: ${(props) => (props.active ? Colours.secondaryText : Colours.primaryText)};
  font-size: 1em;
  pointer-events: none;
  position: absolute;
  transition: all 0.2s ease-in-out;
  transform-origin: left top;
  transform: ${(props) => (props.active ? 'translate3d(0, -80%, 0) scale(0.7)' : 'none')};
`;

const Input = styled.input<{ active: boolean }>`
  background-color: transparent;
  border: none;
  color: ${Colours.primaryText};
  font-size: 1em;
  margin: 0;
  outline: none;
  padding: 0 0 20px 0;
  width: 100%;
  min-height: 3rem;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:active,
  &:-webkit-autofill:focus {
    background-color: inherit !important;
    color: inherit;
  }

  &::placeholder {
    color: #9b9b9b;
    opacity: ${(props) => (props.active ? 1 : 0)};
    transition: opacity 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }

  &[type='date'] {
    -webkit-appearance: textfield;
    padding-left: 4rem;

    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }
`;

export default {
  Wrapper,
  Label,
  Input,
};
