import { createAction, createAsyncAction } from 'typesafe-actions';
import { UserProfile } from 'shared/types/UserProfile';

const login = createAsyncAction('login/LOGIN', 'login/LOGIN_SUCCESS', 'login/LOGIN_FAILURE')<
  { username: string; password: string },
  UserProfile,
  { code: string; message: string }
>();

const logout = createAction('login/LOGOUT')<void>();

const checkEmail = createAsyncAction(
  'login/CHECK_EMAIL',
  'login/CHECK_EMAIL_SUCCESS',
  'login/CHECK_EMAIL_FAILURE'
)<string, boolean, Error>();

const resetPassword = createAsyncAction(
  'login/RESET_PASSWORD',
  'login/RESET_PASSWORD_SUCCESS',
  'login/RESET_PASSWORD_FAILURE'
)<string, void, { code: string; message: string }>();

const clearResetPasswordState = createAction('login/CLEAR_RESET_STATE')<void>();

export default {
  login,
  logout,
  checkEmail,
  resetPassword,
  clearResetPasswordState,
};
