import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { FieldError, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { AnimatePresence, motion } from 'framer-motion';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from 'shared/components/input/Input';
import { ValidationError } from 'shared/components/validation-error/ValidationError';
import { Match } from 'shared/types/Match';
import Styled from './AddMatch.styles';
import { Sidebar } from '../../shared/components/sidebar/sidebar';

export const validationSchema = yup.object({
  title: yup.string().required('Please enter match title.'),
  date: yup.string().required('Please enter match date'),
  kickOff: yup.string().required('Please enter kick-off time.'),
});

export const AddMatch: React.FC<RouteComponentProps> = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [step, setStep] = useState('step1');
  const { register, handleSubmit, errors, control, reset, watch, setValue, formState } = useForm<
    Partial<Match & { venue?: string; date?: string }>
  >({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (values: any): void => {
    console.log(values);
  };

  return (
    <AnimatePresence initial={false}>
      {
        (
          {
            step1: (
              <Styled.Wrapper
                initial={{ opacity: 0, transform: 'translateX(100%)' }}
                animate={{ opacity: 1, transform: 'translateX(0px)' }}
                exit={{ opacity: 0, transform: 'translateX(-100%)' }}
              >
                <Input type="radio" name="type" label="Vs match" />
                <Input type="radio" name="type" label="Squad mode" />
                <button onClick={() => setStep('step2')}>next</button>
              </Styled.Wrapper>
            ),
            step2: (
              <Styled.Wrapper
                initial={{ opacity: 0, transform: 'translateX(100%)' }}
                animate={{ opacity: 1, transform: 'translateX(0px)' }}
                exit={{ opacity: 0, transform: 'translateX(-100%)' }}
              >
                <Sidebar onClose={() => setSidebarVisible(false)}>settings</Sidebar>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Input label="Title" name="title" ref={register} />
                  <ValidationError message={(errors.title as FieldError)?.message} />

                  <Input label="Venue" name="venue" ref={register} />
                  <ValidationError message={(errors.venue as FieldError)?.message} />

                  <Input label="Date" type="date" name="date" ref={register} />
                  <ValidationError message={(errors.date as FieldError)?.message} />

                  <button onClick={() => setSidebarVisible(true)}>Match settings</button>
                </form>
              </Styled.Wrapper>
            ),
          } as any
        )[step]
      }
    </AnimatePresence>
  );
};
