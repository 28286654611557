import React from 'react';
import Styled from './RadioButtonGroup.styles';

interface Props {
  title: string;
  name: string;
  options: (string | { value: string; label: string })[];
  value: string | number;
}

const _RadioGroup: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { title, options, name, value },
  ref
) => (
  <Styled.Wraper>
    <h6>{title}</h6>
    <Styled.GroupWrapper>
      {options.map((option) => {
        let itemValue, itemLabel;
        if (typeof option === 'string') {
          itemValue = itemLabel = option;
        } else {
          itemValue = option.value;
          itemLabel = option.label;
        }

        return (
          <React.Fragment key={itemValue}>
            <input
              id={`${name}_${itemValue}`}
              ref={ref}
              name={name}
              type="radio"
              value={itemValue}
              defaultChecked={value === itemValue}
            />
            <label htmlFor={`${name}_${itemValue}`}>{itemLabel}</label>
          </React.Fragment>
        );
      })}
    </Styled.GroupWrapper>
  </Styled.Wraper>
);

export const RadioButtonGroup = React.forwardRef(_RadioGroup);
