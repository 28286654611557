import React from 'react';
import styled from 'styled-components/macro';
import { device } from 'shared/styles/MediaQuery';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12vh 0 2.5rem;
  align-items: flex-start;
  flex: 0 0 auto;

  img {
    display: none;
  }

  ${device.tablet} {
    margin: 16.5vh 0;
    align-items: center;

    img {
      display: inline-block;
      align-self: center;
      margin-bottom: 1rem;
    }
  }
`;

export const Header: React.FC<{ title?: string }> = ({ title, children, ...props }) => (
  <Wrapper {...props}>
    <img src="/images/logo.svg" alt="Footify" />
    {title && <h2>{title}</h2>}
  </Wrapper>
);
