export enum Colours {
  primaryBackground = '#070b0f',
  secondaryBackground = '#f8f8f8',
  modalBackground = '#212323',
  primaryElement = '#f8f8f8',
  secondaryElement = '#2721f5',
  accentElement = '#282c2e',
  primaryText = '#ffffff',
  secondaryText = '#999999',
  tertiaryText = '#1b1d1e',

  divider = '#363636',
  box = '#0E1619',
}
