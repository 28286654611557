import styled from 'styled-components/macro';
import { Colours } from '../../styles/Colours';

export interface Props {
  color?: string;
  textColor?: string;
  secondary?: boolean;
}

export const Button = styled.button<Props>`
  align-items: center;
  background-color: ${(props) =>
    props.color ? props.color : props.secondary ? Colours.primaryBackground : Colours.primaryText};
  border-color: transparent;
  border-radius: 2px;
  color: ${(props) =>
    props.textColor
      ? props.textColor
      : props.secondary
      ? Colours.primaryText
      : Colours.primaryBackground};
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  letter-spacing: -0.24px;
  margin-bottom: 1rem;
  min-height: 48px;
  outline: 0;
  transition: opacity 100ms ease-in;
  width: 160px;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.65;
  }

  &:disabled {
    opacity: 0.3;
  }
`;
