import styled from 'styled-components/macro';
import { Colours } from 'shared/styles/Colours';
import { PositionBadge as _PositionBadge } from '../PositionBadge';
import { Avatar as _Avatar } from '../Avatar';
import { IcoSearch as _IcoSearch } from '../../icons/search';

const RoundButton = styled.button<{ iconUrl: string }>`
  background-color: white;
  border-radius: 50%;
  color: black;
  height: 30px;
  width: 30px;
  padding: 0;
  line-height: 0;
  cursor: pointer;
  outline: none;

  :hover {
    background-color: rgba(255, 255, 255, 0.9);
  }

  ::before {
    content: url('${(props) => props.iconUrl}');
  }
`;

const IcoSearch = styled(_IcoSearch)`
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  color: ${Colours.secondaryText};
`;

const Wrapper = styled.div`
  align-items: center;
  background-color: #2e3032;
  border-radius: 3rem;
  color: white;
  display: flex;
  max-width: 660px;
  padding: 15px 30px;
  position: relative;
  width: 100%;

  ${RoundButton} {
    display: none;
  }

  &:hover {
    ${RoundButton} {
      display: inline-block;
    }
  }

  input {
    background-color: transparent;
    border: unset;
    line-height: 1.8;
    flex: 1;
    outline: none;
    color: white;
    margin-left: 1rem;
  }
`;

const SuggestionsWrapper = styled.div`
  background-color: #1b1d1e;
  border-radius: 3rem;
  left: 0;
  margin-top: 3px;
  padding: 35px 56px;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1;
`;

const Suggestions = styled.ul`
  max-height: 50vh;
  overflow-y: auto;
  padding: 0 2rem 0 0;

  li {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 15px 0;
    border-bottom: 1px solid ${Colours.divider};
  }
`;

const PositionBadge = styled(_PositionBadge)`
  transform: scale(0.75);
`;

const Avatar = styled(_Avatar)`
  margin: 0 0.5rem 0 0.5rem;
`;

export default {
  IcoSearch,
  Wrapper,
  SuggestionsWrapper,
  Suggestions,
  RoundButton,
  PositionBadge,
  Avatar,
};
