import React from 'react';

export const TermsOfUse: React.FC = () => (
  <>
    <h2>Terms of Use</h2>
    <p>
      Please read these Terms carefully. By using MailChimp or signing up for an account, you’re
      agreeing to these Terms, which will result in a legal agreement between you and MailChimp
      (“Agreement”). We’ll start with the basics, including a few definitions that should help you
      understand these Terms. MailChimp (“MailChimp,” “we,” or “us”) is an online marketing platform
      (the “Service”) offered through the URL www.mailchimp.com (we’ll refer to it as the “Website”)
      that allows you to, among other things, create, send, and manage certain marketing campaigns,
      including, without limitation, emails, advertisements, and mailings (each a “Campaign,” and
      collectively, “Campaigns”). MailChimp is a Georgia limited liability company whose legal name
      is The Rocket Science Group LLC d/b/a MailChimp. MailChimp has employees, independent
      contractors, and representatives (“our Team”). As a customer of the Service or a
      representative of an entity that’s a customer of the Service, you’re a “Member” according to
      this Agreement (or “you”).
      <br />
      These Terms of Use (“Terms,” including our Acceptable Use Policy, API Guidelines, Copyright
      and Trademark Policy, and Brand Guidelines define the terms and conditions under which you’re
      allowed to use the Service in accordance with the Agreement, and how we’ll treat your account
      while you’re a Member. If you don’t agree to these Terms, you must immediately discontinue
      your use of the Service.
    </p>
    <p>
      Some features of the Service are offered as add-ons to your MailChimp account (“Add-ons”).
      Unless expressly stated otherwise, these Terms apply to the use of Add-ons. Some Add-ons are
      intended for particular use cases and may also have additional terms or restrictions
      (“Additional Terms”). Additional Terms for Add-ons can be found on the Website at
      mailchimp.com/legal/additional-terms, and Members agree to the applicable Additional Terms at
      the time they choose to add the corresponding Add-on.
    </p>
    <p>
      MailChimp uses Google Maps to provide certain features of the Service, and, as a result, we
      are contractually obligated to make our Members aware of certain terms related to the use of
      such features. Therefore, you acknowledge and agree that by signing up for an account and
      using the Service, you are also bound by the Google Maps/Google Earth Additional Terms of
      Service (including the Google Privacy Policy).
    </p>
    <p>If you have any questions about our Terms, feel free to contact us.</p>
  </>
);
