import styled from 'styled-components/macro';
import { Colours } from '../../../shared/styles/Colours';

const Page = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;

const ImageInput = styled.label`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 50%;
  color: ${Colours.primaryBackground};
  cursor: pointer;
  display: flex;
  height: 3rem;
  justify-content: center;
  margin-top: 1.8rem;
  width: 3rem;

  input[type='file'] {
    display: none;
  }
`;

export default {
  Page,
  ImageInput,
};
