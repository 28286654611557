import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from 'shared/components/input/Input';
import { ValidationError } from 'shared/components/validation-error/ValidationError';
import { Modal } from 'shared/components/modal/Modal';
import { Position } from 'shared/components/position/Position';
import { UserProfile } from 'shared/types/UserProfile';
import { Loader } from 'shared/icons/loader';
import { playingStylesForPosition } from 'shared/types/PlayingStyles';
import { Button } from 'shared/components/buttons/Button';
import { ImageInput } from 'shared/components/input/ImageInput';
import { AppState } from '../../store/rootReducer';
import { Location } from '../register/Location/Location';
import { authdUserProfileSelector } from '../profile/store/profileSelectors';
import profileActions from '../profile/store/profileActions';
import { RadioList } from './RadioList';
import { RadioButtonGroup } from './RadioButtonGroup';
import { validationSchema } from './utils';
import Styled from './EditProfile.styles';

export const EditProfile: React.FC<RouteComponentProps> = () => {
  const profile = useSelector(authdUserProfileSelector);
  const isSaving = useSelector((state: AppState) => state.profile.isSaving);
  const dispatch = useDispatch();
  const [locationModalShows, setLocationModalShows] = useState(false);
  const { register, handleSubmit, errors, control, reset, watch, setValue, formState } = useForm<
    Partial<UserProfile>
  >({
    defaultValues: profile ?? undefined,
    mode: 'onChange',
    resolver: yupResolver(validationSchema(profile?.email)),
  });
  const watchProfileType = watch('profileType', profile?.profileType);
  const watchPosition = watch('position', profile?.position);
  const playingPositions = watchPosition ? playingStylesForPosition(watchPosition) : [];

  const onSubmit = (values: Partial<UserProfile>): void => {
    dispatch(profileActions.saveProfile.request(values));
  };

  useEffect(() => {
    profile && reset(profile);
  }, [profile, reset]);

  if (!profile) {
    return (
      <Styled.Page>
        <Loader />
      </Styled.Page>
    );
  }

  return (
    <Styled.Page>
      <Styled.Header>
        <h1>Edit profile</h1>
      </Styled.Header>

      <Controller
        name="profileImage"
        control={control}
        as={<ImageInput />}
        defaultValue={profile.profileImagePathThumbnail}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Input label="Full name:" name="fullName" ref={register} value={profile?.fullName} />
        <ValidationError message={(errors.fullName as FieldError)?.message} />

        <Input label="Email:" name="email" ref={register} value={profile?.email} />
        <ValidationError message={(errors.email as FieldError)?.message} />

        <Input label="DOB:" type="date" name="dob" ref={register} value={profile?.dob} />
        <ValidationError message={(errors.dob as FieldError)?.message} />

        <Input
          label="Where are you based"
          name="location"
          autoComplete="off"
          ref={register}
          value={profile?.location}
          onFocus={() => setLocationModalShows(true)}
        />
        <ValidationError message={(errors.location as FieldError)?.message} />

        <RadioButtonGroup
          title="Profile type"
          name="profileType"
          value={profile.profileType}
          options={[
            { value: '1', label: 'Playing profile' },
            { value: '2', label: 'Non-playing profile' },
          ]}
          ref={register}
        />

        {watchProfileType === '1' && (
          <>
            <RadioButtonGroup
              title="Gender"
              name="gender"
              value={profile.gender}
              options={['Male', 'Female']}
              ref={register}
            />

            <RadioButtonGroup
              title="Preferred foot"
              name="preferredFoot"
              value={profile.preferredFoot}
              options={['Left', 'Both', 'Right']}
              ref={register}
            />

            <h6>Preferred position</h6>
            <Controller
              name="position"
              control={control}
              as={<Position />}
              defaultValue={profile.position}
            />

            <Controller
              name="playingStyle"
              control={control}
              defaultValue={profile.playingStyle}
              render={(props) => (
                <RadioList title="Playing style:" options={playingPositions} {...props} />
              )}
            />
          </>
        )}
        <Styled.Controls>
          <Button type="button" onClick={() => window.history.back()}>
            Back
          </Button>
          <Button type="submit" disabled={isSaving || !formState.isDirty}>
            Save
            {isSaving && <Loader />}
          </Button>
        </Styled.Controls>
      </form>

      <Modal
        onClose={() => setLocationModalShows(false)}
        isOpen={locationModalShows}
        contentStyle={{ height: '100%' }}
      >
        <Location
          onCancel={() => setLocationModalShows(false)}
          onLocationSelected={(location) => {
            setValue('location', location);
            setLocationModalShows(false);
          }}
        />
      </Modal>
    </Styled.Page>
  );
};
