import styled from 'styled-components/macro';

const Page = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  h1 {
    margin: 1.6rem 0 0;
  }
`;

export default {
  Page,
};
