import { object, string, boolean } from 'yup';
import auth from '../../log-in/api';

export const validateUniqueEmail = async (value: string): Promise<boolean> => {
  if (!value) return true;
  const emailRegistered = await auth.checkEmailExists(value).catch((e) => {
    if (e.code === 'auth/network-request-failed') {
      console.log('Email check failed:', e.message);
      return true;
    }

    return false;
  });

  return !emailRegistered;
};

export const validationSchema = object({
  fullName: string().required('Please enter your full name.'),
  email: string()
    .required('Please enter a valid email address')
    .email('Please enter a valid email address')
    .test('unique-email', 'This email address is already registered', validateUniqueEmail),
  dob: string().required('Please enter your date of birth'),
  location: string().required('Please select your location.'),
  password: string().min(6, 'Password is too weak.').required('Please enter a password'),
  tc: boolean().oneOf([true], 'Please agree to our Terms And Conditions'),
});
