import firebase from 'firebase/app';

export const configProd = {
  apiKey: 'AIzaSyBWN3faoYTPVPHzKbcu_itOK1GXsLYgo3k',
  authDomain: 'footify-2e17f.firebaseapp.com',
  databaseURL: 'https://footify-2e17f.firebaseio.com',
  projectId: 'footify-2e17f',
  storageBucket: 'footify-2e17f.appspot.com',
  messagingSenderId: '461796106960',
  appId: '1:461796106960:web:ddfb587949bb0c07',
};

export const configDev = {
  apiKey: 'AIzaSyBWN3faoYTPVPHzKbcu_itOK1GXsLYgo3k',
  authDomain: 'footify-2e17f.firebaseapp.com',
  databaseURL: 'https://footify-2e17f.firebaseio.com',
  projectId: 'footify-2e17f',
  storageBucket: 'footify-2e17f.appspot.com',
  messagingSenderId: '461796106960',
  appId: '1:461796106960:web:ddfb587949bb0c07',
};

const firebaseConfig = process.env.NODE_ENV === 'production' ? configProd : configDev;

class Firebase {
  app: firebase.app.App;

  constructor() {
    this.app = firebase.initializeApp(firebaseConfig);
  }

  signInAnonymously(): Promise<firebase.auth.UserCredential> {
    return firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => firebase.auth().signInAnonymously());
  }
}

export default Firebase;
