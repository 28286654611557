import { createSelector } from 'reselect';
import { AppState } from 'store/rootReducer';
import { UserProfile } from 'shared/types/UserProfile';
import { Steps } from '../Steps.enum';
import { RegisterState } from './registerReducer';
import { playingStylesForPosition } from '../../../shared/types/PlayingStyles';

export const registerStateSelector = (state: AppState): RegisterState => state.register;
export const isFetchingSelector = (state: AppState): boolean => state.register.isFetching;

export const registrationDataSelector = createSelector<
  AppState,
  RegisterState,
  Partial<UserProfile>
>(registerStateSelector, (state: RegisterState) => state.values);

export const progressSelector = createSelector<
  AppState,
  RegisterState,
  { activeStep: Steps; prevStep?: Steps }
>(registerStateSelector, (state: RegisterState) => {
  const stepsList = Object.values(Steps);
  const prevStep = stepsList[stepsList.indexOf(state.activeStep) - 1];
  return {
    activeStep: state.activeStep,
    prevStep: prevStep ?? '/',
  };
});

export const playingStyleOptions = createSelector(
  registrationDataSelector,
  (data: Partial<UserProfile>) => {
    return data.position ? playingStylesForPosition(data.position) : [];
  }
);
