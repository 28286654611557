import React from 'react';

export const Loader: React.FC<{ style?: any }> = (props) => (
  <svg height="1rem" viewBox="0 0 160 100" preserveAspectRatio="xMidYMid" {...props}>
    <rect x="10" y="30" width="35" height="50" fill="currentColor">
      <animate
        attributeName="y"
        calcMode="spline"
        values="-200;25;25"
        keyTimes="0;0.5;1"
        dur="1.4"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
        begin="0s"
        repeatCount="indefinite"
      />
      <animate
        attributeName="height"
        calcMode="spline"
        values="500;50;50"
        keyTimes="0;0.5;1"
        dur="1.4"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
        begin="0s"
        repeatCount="indefinite"
      />
    </rect>
    <rect x="60" y="30" width="35" height="50" fill="currentColor">
      <animate
        attributeName="y"
        calcMode="spline"
        values="-143.75;25;25"
        keyTimes="0;0.5;1"
        dur="1.4"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
        begin="0.15s"
        repeatCount="indefinite"
      />
      <animate
        attributeName="height"
        calcMode="spline"
        values="387.5;50;50"
        keyTimes="0;0.5;1"
        dur="1.4"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
        begin="0.15s"
        repeatCount="indefinite"
      />
    </rect>
    <rect x="110" y="30" width="35" height="50" fill="currentColor">
      <animate
        attributeName="y"
        calcMode="spline"
        values="-87.5;25;25"
        keyTimes="0;0.5;1"
        dur="1.4"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
        begin="0.3s"
        repeatCount="indefinite"
      />
      <animate
        attributeName="height"
        calcMode="spline"
        values="275;50;50"
        keyTimes="0;0.5;1"
        dur="1.4"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
        begin="0.3s"
        repeatCount="indefinite"
      />
    </rect>
  </svg>
);
