import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { SearchResult } from '../../types/SearchResult';
import { PlayingPosition } from '../../types/PlayingPosition';
import Styled from './SearchBar.styles';

export interface Props {
  queryResolver: (q: string) => Promise<SearchResult>;
  onClick: (item: any) => void;
}

export const SearchBar: React.FC<Props> = ({ onClick, queryResolver, ...props }) => {
  const [suggestions, setSuggestions] = useState<SearchResult | null>(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [value] = useDebounce(search, 500);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value);
  };

  const handleClick = (item: any): void => {
    setSearch('');
    onClick(item);
  };

  const highlight = (name: string): (string | JSX.Element)[] =>
    name
      .split(RegExp(`(${value})`))
      .map((value, index) => (index % 2 === 0 ? value : <b key={index}>{value}</b>));

  useEffect(() => {
    setLoading(true);
    value ? queryResolver(value).then(setSuggestions) : setSuggestions(null);
    setLoading(false);
  }, [value]);

  return (
    <Styled.Wrapper {...props}>
      <Styled.IcoSearch />
      <input onChange={handleChange} value={search} placeholder="Player search" />
      <Styled.RoundButton iconUrl={'/images/close.svg'} onClick={() => setSearch('')} />
      {suggestions && (
        <Styled.SuggestionsWrapper>
          <Styled.Suggestions>
            {suggestions.map((suggestion) => (
              <li key={suggestion.id} onClick={() => handleClick(suggestion)}>
                {suggestion.type === 'player' && suggestion.position && (
                  <Styled.PositionBadge position={suggestion.position as PlayingPosition} />
                )}
                <Styled.Avatar src={suggestion.avatar} />
                <span>{highlight(suggestion.name)}</span>
              </li>
            ))}
          </Styled.Suggestions>
        </Styled.SuggestionsWrapper>
      )}
    </Styled.Wrapper>
  );
};
