import styled from 'styled-components/macro';
import { Colours } from '../../styles/Colours';

export interface Props {
  isActive: boolean;
}

export const ToggleButton = styled.button<Props>`
  border: 1px solid ${Colours.primaryText};
  border-radius: 2px;
  background-color: ${(props) =>
    props.isActive ? Colours.primaryText : Colours.primaryBackground};
  color: ${(props) => (props.isActive ? Colours.primaryBackground : Colours.primaryText)};
  cursor: pointer;
  font-size: 0.9rem;
  letter-spacing: -0.24px;
  padding: 0.8rem;
  position: relative;
  outline: none;
  transition: background-color 100ms ease-in, color 100ms ease-in;

  &:after {
    position: absolute;
    left: 7px;
    top: 0;
    display: ${(props) => (props.isActive ? 'inline-block' : 'none')};
    content: url('data:image/svg+xml; utf8, 
      <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.077 0a.942.942 0 00-.716.323l-3.328 3.87-1.432-1.469a.949.949 0 00-.678-.28c-.51-.001-.923.39-.923.873 0 .23.094.437.245.592L2.4 6.12c.169.172.41.281.678.281a.942.942 0 00.716-.323l4-4.65A.845.845 0 008 .873C8 .392 7.587 0 7.077 0z"
          fill="${(props) => (props.isActive ? 'black' : 'white')}"
         />
      </svg>
    ');
  }

  &:hover:after {
    display: inline-block;
  }
`;
