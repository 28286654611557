import React from 'react';
import styled from 'styled-components/macro';
import { Colours } from 'shared/styles/Colours';
import { device } from 'shared/styles/MediaQuery';
import { IcoBack } from 'shared/icons/back';
import { Steps } from '../Steps.enum';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  line-height: 1rem;

  ${device.tablet} {
    display: none;
  }
`;

const StepsList = styled.ul`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 auto;
`;

const Step = styled.li<{ isCompleted?: boolean }>`
  border-bottom: 2px solid ${Colours.secondaryText};
  width: 1.6rem;

  &:not(:last-of-type) {
    margin-right: 2px;
  }

  &:after {
    border-bottom: 2px solid ${Colours.primaryText};
    content: '';
    display: block;
    margin-bottom: -2px;
    width: ${({ isCompleted }) => (isCompleted ? '100%' : 0)};
    transition: width 240ms ease-in 150ms;
  }
`;

const NavButton = styled.button`
  all: unset;
  padding: 0 1rem;
  cursor: pointer;

  &:disabled {
    opacity: 0.7;
  }
`;

interface Props {
  steps: string[];
  activeStep: Steps;
  isActiveStepValid: boolean;
  onCancel: () => void;
  onNext: () => void;
}

export const ProgressMobile: React.FC<Props> = ({
  steps,
  activeStep,
  isActiveStepValid,
  onCancel,
  onNext,
}) => {
  const stepsCompleted = steps.slice(0, steps.indexOf(activeStep) + 1);
  const isStepCompleted = (step: Steps): boolean => stepsCompleted.indexOf(step) !== -1;

  return (
    <Wrapper>
      <NavButton onClick={onCancel}>
        <IcoBack />
      </NavButton>
      <StepsList>
        {steps.map((step) => (
          <Step key={step} isCompleted={isStepCompleted(step as any)} />
        ))}
      </StepsList>
      <NavButton onClick={onNext} disabled={!isActiveStepValid}>
        Next
      </NavButton>
    </Wrapper>
  );
};
