import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Avatar as AvatarImg } from 'shared/components/Avatar';
import { IcoPlus } from 'shared/icons/plus';
import { Header } from '../components/Header';
import Styled from './Avatar.style';

interface Props {
  onChange: (file: string) => void;
  value?: string;
}

const Avatar: React.FC<RouteComponentProps & Props> = ({ onChange, value: initialValue }) => {
  const [image, setImage] = useState<string | undefined>(initialValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      if (event.target?.result) {
        setImage(event.target.result as string);
        onChange(event.target.result as string);
      }
    };

    if (event.target.files) {
      fileReader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <Styled.Page>
      <Header title="Profile pic" />
      <AvatarImg size={120} src={image} />
      <Styled.ImageInput>
        <IcoPlus />
        <input type="file" accept="image/*" onChange={handleChange} />
      </Styled.ImageInput>
      {
        // we should add capture option for mobile devices
        // <Styled.ImageInput>
        //   <input type="file" accept="image/*" capture="user" />
        // </Styled.ImageInput>
      }
    </Styled.Page>
  );
};

export default Avatar;
