import React from 'react';
import { RouteComponentProps } from '@reach/router';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { unfold, zip } from 'ramda';
import { Tab } from 'shared/components/tabs/tabs';
import { useLiveMatch } from 'shared/hooks/useLiveMatch';
import { Event } from './components/Event';
import Styled from './Match.styles';

dayjs.extend(duration);

const LiveMatch: React.FC<RouteComponentProps & { idOrName?: string }> = ({
  idOrName,
  ...props
}) => {
  const match = useLiveMatch(idOrName!);

  if (match === null) {
    return (
      <Styled.Wrapper {...props}>
        <h2>No match is being played by this squad.</h2>
        <Styled.Loader />
      </Styled.Wrapper>
    );
  }

  const matchDate = dayjs.unix(match.startAt.seconds).format('MM ddd, MMM YYYY. HH:mm ');
  const duration = dayjs.duration(Math.ceil(match!.time), 'seconds');

  const { homeTeamPlayersNames: homePlayers, awayTeamPlayersNames: awayPlayers } = match!;

  const maxTeamPlayers = Math.max(homePlayers.length, awayPlayers.length);
  const fillBlanks =
    (team: string[]) =>
    (n: number): false | [string, number] =>
      n < maxTeamPlayers && [team[n] || '', n + 1];
  const lineUps = zip(unfold(fillBlanks(homePlayers), 0), unfold(fillBlanks(awayPlayers), 0));

  return (
    <Styled.Wrapper {...props}>
      <Styled.Logo />
      <h5>{match!.title}</h5>
      <Styled.Date>{matchDate}</Styled.Date>
      <Styled.Duration>
        {Math.floor(duration.asMinutes())} :{' '}
        {duration.seconds() < 10 ? '0' + duration.seconds() : duration.seconds()}
      </Styled.Duration>
      <h3>({match.stateTime})</h3>
      <Styled.Result>
        <Styled.Score>
          {match!.score0}
          <span>{match!.homeTeamName}</span>
        </Styled.Score>
        <Styled.Score>
          {match!.score1}
          <span>{match!.awayTeamName}</span>
        </Styled.Score>
      </Styled.Result>
      <Styled.Tabs>
        <Tab header={<span>Match Report</span>}>
          {match!.events
            ?.sort((a, b) => (a.time > b.time ? 1 : -1))
            .map((event, idx) => (
              <Event key={idx} event={event} />
            ))}
        </Tab>
        <Tab header={<span>Line-ups</span>}>
          {lineUps.map(([home, away]) => (
            <Styled.LineUpRow key={home}>
              <span>{home}</span> <span>-</span> <span>-</span> <span>{away}</span>
            </Styled.LineUpRow>
          ))}
        </Tab>
      </Styled.Tabs>
    </Styled.Wrapper>
  );
};

export default LiveMatch;
