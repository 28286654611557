import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../services/auth/AuthContext';
import { Match } from '../types/Match';
import { Squad } from '../types/Squad';

export function useLiveMatch(squadIdOrName: string): Match | null {
  const [matchId, setMatchId] = useState<string | null>(null);
  const [match, setMatch] = useState<any>(null);
  const [events, setEvents] = useState<any[]>([]);

  const { firebase } = useContext(AuthContext);

  useEffect(() => {
    firebase &&
      Promise.all([
        firebase
          .firestore()
          .collection('Squad')
          .where('squadId', '==', squadIdOrName)
          .get()
          .then((result) => (result.empty ? null : result.docs[0].data())),
        firebase
          .firestore()
          .collection('Squad')
          .where('squadUrl', '==', squadIdOrName)
          .get()
          .then((result) => (result.empty ? null : result.docs[0].data())),
      ]).then(([byId, byName]) => {
        const squad = byId || byName;
        return (
          squad &&
          firebase
            .firestore()
            .collection('Matches')
            .where('homeSquadId', '==', squad.squadId)
            .where('state', '==', 'valid')
            .where('stateTime', 'in', ['live', 'background', 'paused'])
            .onSnapshot((snapshot) => {
              setMatchId(snapshot.empty ? null : snapshot.docs[0].ref.id);
              setMatch(snapshot.empty ? null : snapshot.docs[0].data());
            })
        );
      });
  }, [squadIdOrName, firebase]);

  useEffect(() => {
    if (!matchId || !firebase) {
      return undefined;
    }

    return firebase
      .firestore()
      .collection(`Matches/${matchId}/Events`)
      .onSnapshot((snapshot) => setEvents(snapshot.docs.map((event) => event.data())));
  }, [matchId]);

  return (
    match && {
      ...match,
      events,
    }
  );
}
