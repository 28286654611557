import styled from 'styled-components/macro';
import { device } from 'shared/styles/MediaQuery';
import { Colours } from 'shared/styles/Colours';

const Hint = styled.p`
  color: ${Colours.secondaryText};
  margin-bottom: 2rem;

  a {
    color: ${Colours.primaryText};
  }
`;

const HintSmall = styled.p`
  color: ${Colours.secondaryText};
  font-size: 0.75rem;
  margin-top: 0.25rem;

  a {
    color: ${Colours.primaryText};
  }
`;

const Page = styled.div`
  display: flex;
  flex: 1;

  ${device.tablet} {
    align-items: flex-start;
  }

  form {
    display: flex;
    flex: 1 0 auto; /* safari - or form will collapse */
    flex-direction: column;
    max-width: 350px;

    ${device.tablet} {
      h1,
      ${Hint} {
        display: none;
      }
    }
  }
`;

const CoverImage = styled.div`
  background-image: linear-gradient(90deg, rgba(7, 11, 15, 0) 0%, rgb(8, 11, 14) 100%),
    url('/images/login_bg_small.jpg');
  background-position: 50%;
  background-size: cover;
  display: none;
  align-items: flex-end;
  height: 100%;
  overflow: hidden;
  padding-top: 30vh;
  width: 50%;

  ${device.tablet} {
    display: flex;
    flex-direction: column;

    h1,
    ${Hint} {
      width: 220px;
    }
  }
`;

const ScrollPane = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding-left: 1rem;
  padding-top: 15vh;

  ${device.tablet} {
    padding-top: 20vh;
  }
`;

const Logo = styled.img`
  align-self: center;
  margin-bottom: 3rem;

  ${device.tablet} {
    align-self: start;
    margin-bottom: 1rem;
  }
`;

const Footer = styled.p`
  color: ${Colours.secondaryText};
  margin: auto 0 2.4rem;
  padding-top: 2rem;
  font-size: 0.8rem;
`;

export default {
  Page,
  Logo,
  Hint,
  HintSmall,
  CoverImage,
  ScrollPane,
  Footer,
};
