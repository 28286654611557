import { createAsyncAction } from 'typesafe-actions';
import { UserProfile } from 'shared/types/UserProfile';

const loadProfile = createAsyncAction(
  'profile/LOAD',
  'profile/LOAD_SUCCESS',
  'profile/LOAD_FAILURE'
)<string, UserProfile, Error>();

const saveProfile = createAsyncAction(
  'profile/SAVE',
  'profile/SAVE_SUCCESS',
  'profile/SAVE_FAILURE'
)<Partial<UserProfile>, Partial<UserProfile>, Error>();

const followUser = createAsyncAction('user/FOLLOW', 'user/FOLLOW_SUCCESS', 'user/FOLLOW_FAILURE')<
  string,
  void,
  Error
>();

export default {
  loadProfile,
  saveProfile,
  followUser,
};
