import styled from 'styled-components/macro';
import { Colours } from '../../styles/Colours';

const PositionGrid = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(5, 3rem);
  grid-template-rows: repeat(7, 3rem);
  margin: 3rem 0;
`;

const PlayingPosition = styled.span<{
  bgColour: string;
  name: string;
  isActive?: boolean;
  color?: string;
  col?: number;
}>`
  background-color: ${({ isActive, bgColour }) => (isActive ? bgColour : 'transparent')};
  border-radius: 50%;
  border: 2px solid ${Colours.primaryText};
  cursor: pointer;
  font-size: 0.75rem;
  grid-column-start: ${(props) => props.col ?? 'auto'};
  height: 3rem;
  outline: none;
  position: relative;
  width: 3rem;
  overflow: hidden;
  transition: background-color 0.15s ease-in;

  &:hover:before {
    background-color: ${(props) => props.bgColour};
    color: ${(props) => props.color ?? Colours.primaryBackground};
  }

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '${(props) => props.name}';
    color: ${({ isActive, color }) => (isActive ? color : Colours.primaryText)};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s ease-in, color 0.1s ease-in 0.1s;
  }
`;

export default {
  PositionGrid,
  PlayingPosition,
};
