import React, { FocusEvent, FocusEventHandler, useEffect, useState } from 'react';
import Styled from './Input.styles';

interface Props {
  name: string;
  label: string;
  autoComplete?: string;
  value?: string;
  type?: string;
  onFocus?: FocusEventHandler<HTMLInputElement>;
}

const _Input: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { label, value, name, type, onFocus, ...props },
  ref
) => {
  const [active, setActive] = useState<boolean>(!!value && value?.length > 0 && type !== 'date');

  const handleFocus = (event: FocusEvent<HTMLInputElement>): void => {
    onFocus !== undefined && onFocus(event);
    !event.defaultPrevented && type !== 'date' && setActive(true);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>): void => {
    type !== 'date' && setActive(event.target.value?.length !== 0);
  };

  useEffect(() => setActive(!!value && value.length > 0 && type !== 'date'), [value, type]);

  return (
    <Styled.Wrapper>
      <Styled.Label htmlFor={name} active={active}>
        {label}
      </Styled.Label>
      <Styled.Input
        active={active}
        onBlur={handleBlur}
        onFocus={handleFocus}
        ref={ref}
        type={type}
        name={name}
        defaultValue={value}
        {...props}
      />
    </Styled.Wrapper>
  );
};

export const Input = React.forwardRef(_Input);
