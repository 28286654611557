import { getType, Reducer } from 'typesafe-actions';
import { mergeRight, omit } from 'ramda';
import { AnyAction } from 'redux';
import { acceptSquadInvite, rejectSquadInvite } from '../modules/squad-profile/store/squadActions';
import actions from './uiActions';

export interface SidebarState {
  component: 'menu' | 'notifications' | 'playerProfile' | null;
  props?: Record<string, any>;
}

export interface UIState {
  sidebar: SidebarState;
  notifications: Record<string, any>;
  user: {
    uid: string;
    email: string;
    emailVerified: boolean;
  } | null;
}

export const initialAppState: UIState = {
  sidebar: { component: null, props: {} },
  notifications: {},
  user: null,
};

const UIReducer: Reducer<UIState, AnyAction> = (state = initialAppState, action: AnyAction) => {
  const { type, payload } = action;

  switch (type) {
    case getType(actions.userAuthStateChanged): {
      return state.user === action.payload ? state : mergeRight(state, { user: action.payload });
    }

    case getType(actions.sidebarChange): {
      return mergeRight(state, {
        sidebar: payload,
      });
    }

    case getType(actions.loadNotifications.success): {
      return mergeRight(state, {
        notifications: payload?.reduce((acc: Record<string, any>, item: any) => {
          acc[item.id] = item;
          return acc;
        }, state.notifications),
      });
    }

    case getType(acceptSquadInvite.success):
    case getType(rejectSquadInvite.success): {
      return {
        ...state,
        notifications: omit([payload], state.notifications),
      };
    }

    default:
      return state;
  }
};

export default UIReducer;
